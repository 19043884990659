import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 236px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Select = styled.select`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: cente;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: #fff;
  padding: 0.875rem;
  border-radius: 5px;
  transition: background-color 0.2s;
  max-width: 14rem;

  svg {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }
`;

export const Loading = styled.div`
  animation: loadAnim 2s infinite;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
