import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;
  border-radius: 8px;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 648px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  outline: 1px solid #D1DDF1;
  height: 2rem;
  border-radius: 5px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #006EBF;
  }

  input {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0.25rem 0.5rem;
    width: 20rem;
    height: 2rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: background-color 0.2s;

      svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #006EBF;
    }

    &:hover {
      background-color: var(--gray-100);
    }

    &:active {
      background-color: var(--gray-50);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const ButtonCreate = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: #006EBF;
  border-radius: 5px;
  color: #fff;
  padding: 0.75rem 1rem;
  transition: background-color 0.2s;
  cursor: pointer;

  @media (max-width: 648px) {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  svg {
    margin-right: 0.75rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .pageControl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

   button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #006EBF;
    border-radius: 8px;
    outline: none;
    background: #D1DDF1;
    padding: 0.25rem;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }

    &:hover {
      background: #006EBF;
        color: var(--gray-50);
      }

      &:active {
        color: var(--gray-400);
      }
   }

    span {
      font-size: 1.25rem;
      font-weight: 700;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  table {
    border-collapse: collapse;

    thead {
      text-align: left;
      vertical-align: center;
      height: 2.5rem;
      border-bottom: 2px solid #D1DDF1;

      tr {
        width: 100%;
        th {
        padding: 0.5rem 0;
      }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--gray-200);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.2s;

        &:hover {
          background: #ECEFF9;
        }
      }

      td {
          display: flex;
          padding: 0.5rem 0;

          img {
          object-fit: contain;
          width: 144px;
          height: 96px;
          /* border-radius: 5px; */
          box-shadow: 0 0 4px 0px rgba(0,0,0,0.3);
        }

        .containerLogsInfo {
          display: flex;
          height: auto;

          .userContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            width: 500px;
            padding-left: 1rem;
            padding-right: 1rem;

            span {
              font-size: 0.875rem;
              margin-left: 0.75rem;
              width: 500px;
              word-break: break-all;
            }
          }

          .createdContainer {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 1px solid var(--gray-100);
            color: var(--purple-identity);
            font-size: 1rem;

            span {
              margin-left: 0.25rem;
            }
          }

          .ipContainer {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 1px solid var(--gray-100);
            color: var(--purple-identity);
            font-size: 1rem;

            span {
              margin-left: 0.25rem;
            }
          }
        }
      }
    }
  }
`;

