import { ChangeEvent} from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiUploadCloud } from 'react-icons/fi';

import { useToast } from '../../hooks/toast';

import Header from '../../components/Header';
import Menu from '../../components/Menu';

import { Container, Content, Body, HeaderBody, FormContainer, Input } from './styles';

const MediaCreateImage = ({ id = 'thismodal', onClose }: any) => {
  const { addToast } = useToast();

  function selectImage(e: ChangeEvent<HTMLInputElement>) {
    if(e.target.files){
      console.log(e.target.files[0].name)
    }
  }

  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  }

  return (
    <Container>
      <Menu />
      <Content>
        <Header pageTitle='Criar Mídia > Imagem' />
        <Body>
          <HeaderBody>
            <Link to="/media/create"><FiArrowLeft /> Voltar</Link>
          </HeaderBody>
          <FormContainer>
            <Input name='description' type='text' placeholder='Descrição' autoFocus required />
            <Input name='credits' type='text' placeholder='Créditos ou Direitos Autorais' required />
            <label htmlFor="file" className="labelFile">
              <FiUploadCloud size={92} color='#524bbc' />
              <h3>Clique para enviar a imagem</h3>
              {/* <div style={{display: 'flex', flexDirection: 'column'}}>
                <strong>Especificações de Imagens:</strong>
                <span>Resolução máxima 1000x1000 pixels.</span>
                <span>Tamanho máximo do arquivo 300 KBytes</span>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <strong>Extensões permitidas:</strong>
                <span>gif, jpg, jpeg, png, webp</span>
              </div> */}
            </label>
            <input type="file" name="file" id="file" accept=".gif,.jpg,.jpeg,.png,.webp" onChange={(e)=>{selectImage(e)}} />
          </FormContainer>
        </Body>
      </Content>
    </Container>
  );
}

export {MediaCreateImage};
