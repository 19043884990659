import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;
  border-radius: 8px;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 648px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  outline: 1px solid #D1DDF1;
  height: 2rem;
  border-radius: 5px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #006EBF;
  }

  input {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0.25rem 0.5rem;
    width: 20rem;
    height: 2rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: background-color 0.2s;

      svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #006EBF;
    }

    &:hover {
      background-color: var(--gray-100);
    }

    &:active {
      background-color: var(--gray-50);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const ButtonCreate = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: #006EBF;
  border-radius: 5px;
  color: #fff;
  padding: 0.75rem 1rem;
  transition: background-color 0.2s;

  @media (max-width: 648px) {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  svg {
    margin-right: 0.75rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  gap: 1rem;
  flex-wrap: wrap;

  .cardContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 18rem;
    border: 1px solid #006EBF;
    border-radius: 8px;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 0.2s;

    .cardTitle {
      display: flex;
      padding: 0.5rem 1rem;
      color: #014F87;
      font-size: 1.5rem;
    }

    .cardActive {
      display: flex;
      padding: 0 1rem;
    }

    .cardPublicView {
      display: flex;
      padding: 0 1rem;
    }

    .cardExpirationDate {
      display: flex;
      padding: 0 1rem;
    }

    .cardQuestionContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.25rem 1rem;
    }

    .cardQuestion {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #dadada;
    }

    .cardFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #dadada;
      padding: 0.5rem 1rem;

      .footerItems {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 2rem;

        svg {
          font-size: 1.5rem;
          color: #014F87;
          transition: all 0.2s;

          &:hover {
            cursor: pointer;
            color: #006feF;
            font-size: 1.75rem;
          }
        }
      }
    }

    /* &:hover {
      box-shadow: 0px 4px 9px rgba(0,0,0,0.5);
      cursor: pointer;
    } */
  }
`;

