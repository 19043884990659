import { useCallback, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import imgAvatarDefault from '../../../assets/default-avatar.png';
import api from '../../../services/api';

import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ListContent
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  avatar: string;
}

interface IEventProps {
  name: string;
  code: string;
}

interface ILogsProps {
  id: string;
  event: IEventProps;
  complement?: string;
  user: IUserProps;
  created_remote_ip: string;
  created_at: Date;
}

export const Logs = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState<ILogsProps[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    try {
      api.get(`/admin/logs?page=${page}`, { timeout: 10000 }).then(response => {
        setLogs(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, page]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const searchContent = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/admin/logs?name=${searchContent}&page=${1}`, { timeout: 10000 });
        setLogs(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const handleBackPage = useCallback(() => {
    if (page >= 2) {
      setIsLoading(true);
      setPage(page - 1);
    }
  }, [page]);

  const handleForwardPage = useCallback(() => {
    if (logs.length === 20) {
      setIsLoading(true);
      setPage(page + 1);
    }
  }, [page, logs]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Relatório: Logs' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input type="search" placeholder='Pesquisar' onKeyPress={(e) => { handleSearch(e) }} />
              </SearchContent>
            </HeaderBody>
            <ListContent>
              <div className='pageControl'>
                <button onClick={handleBackPage} style={{ cursor: 'pointer' }}><FaAngleLeft /></button>
                <span>{page}</span>
                <button onClick={handleForwardPage} style={{ cursor: 'pointer' }}><FaAngleRight /></button>
              </div>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    <tr>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      logs && logs.map((logItem: ILogsProps) =>
                        <tr key={logItem.id}>
                          <td>
                            <div className='containerLogsInfo'>
                              <div className='eventContent'>
                                <span className='eventName' title={logItem.complement?.split('##')[1]}>{logItem.event.name}</span>
                                {/* <span className='eventComplement'>{logItem.complement?.split('##')[1]}</span> */}
                              </div>
                              <div className='eventCode'>
                                <span>{logItem.event.code}</span>
                              </div>
                              <div className='userContainer'>
                                <img src={logItem.user.avatar ? logItem.user.avatar : imgAvatarDefault} alt={logItem.user.name} />
                                <span>{logItem.user.name}</span>
                              </div>
                              <div className='createdContainer'>
                                <span>{new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(logItem.created_at))}</span>
                              </div>
                              <div className='ipContainer'>
                                <span>{logItem.created_remote_ip}</span>
                              </div>
                            </div>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
