import { useCallback, useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import imgAvatarDefault from '../../assets/default-avatar.png';
import imgAudioDefault from '../../assets/audio_icon.png';
import imgVideoDefault from '../../assets/video_icon.png';


import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ListContent
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  avatar: string;
}

interface IFileProps {
  id: string;
  description: string;
  credits: string;
  url: string;
  filename: string;
  user: IUserProps;
  created_at: Date;
}

export let imgUrlReturn = '';
export let imgDescriptionReturn = '';
export let imgCreditsReturn = '';

export const MediaCenterModalContent = ({ id = 'thismodal', onClose }: any) => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<IFileProps[]>([]);
  const [page, setPage] = useState(1);

  const handleDefineReturns = useCallback((url: string, description: string, credits: string) => {
    imgUrlReturn = url;
    imgDescriptionReturn = description;
    imgCreditsReturn = credits;

    addToast({
      type: 'success',
      title: 'Imagem inserida',
      description: `${description}`,
    });

    onClose();

  }, [onClose, addToast]);

  useEffect(() => {
    try {
      api.get(`/medias?page=${page}`, { timeout: 5000 }).then(response => {
        setFiles(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, page]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const searchContent = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/medias?description=${searchContent}&page=${1}`, { timeout: 5000 });
        setFiles(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const defineThumbnailMediaItem = useCallback((mediaObject: string) => {
    const mediaType = mediaObject.split('.')[3];
    if (mediaType === 'mpeg') {
      return imgAudioDefault;
    } else if (mediaType === 'mp4') {
      return imgVideoDefault;
    }
    else {
      return mediaObject;
    }

  }, []);

  function justImages(mediaObject: string) {
    const mediaType = mediaObject.split('.')[3];
    return mediaType === 'jpeg' || mediaType === 'png' || mediaType === 'jpg' || mediaType === 'webp' || mediaType === 'gif';
  }

  const handleBackPage = useCallback(() => {
    if (page >= 2) {
      setIsLoading(true);
      setPage(page - 1);
    }
  }, [page]);

  const handleForwardPage = useCallback(() => {
    if (files.length === 10) {
      setIsLoading(true);
      setPage(page + 1);
    }
  }, [page, files]);

  return (
    <>
      <Container>
        <Content>
          <Body>
            <HeaderBody>
              <SearchContent>
                <input type="search" placeholder='Pesquisar pela descrição' onKeyPress={(e) => { handleSearch(e) }} />
                {/* <button onClick={(handleSearch)}><AiOutlineSearch /></button> */}
              </SearchContent>
            </HeaderBody>
            <ListContent>
              <div className='pageControl'>
                <button onClick={handleBackPage} style={{ cursor: 'pointer' }}><FaAngleLeft /></button>
                <span>{page}</span>
                <button onClick={handleForwardPage} style={{ cursor: 'pointer' }}><FaAngleRight /></button>
              </div>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {files.length > 0 ?
                      <tr>
                        {/* <th>Imagens</th> */}
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      files && files.filter(file => justImages(file.url)).map(
                        (mediaItem: IFileProps) =>
                          <tr key={mediaItem.id}>
                            <td>
                              <img src={mediaItem.url && defineThumbnailMediaItem(mediaItem.url)} alt="Arquivo de imagem" onClick={() => { handleDefineReturns(mediaItem.url, mediaItem.description, mediaItem.credits) }} />
                              <div className='containerMediaInfo'>
                                <div className='descriptionContent'>
                                  <h4>{mediaItem.description}</h4>
                                  <span>Créditos/Direitos Autorais: {mediaItem.credits}</span>
                                </div>
                                <div className='copyURL' onClick={() => { handleDefineReturns(mediaItem.url, mediaItem.description, mediaItem.credits) }}>
                                  <FiExternalLink />
                                  <span>Inserir Mídia</span>
                                </div>
                                <div className='userContainer'>
                                  <img src={mediaItem.user.avatar ? mediaItem.user.avatar : imgAvatarDefault} alt={mediaItem.user.name} />
                                  <span>{mediaItem.user.name}</span>
                                </div>
                                <div className='createdContainer'>
                                  <span>Criado em: {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(mediaItem.created_at))}</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                      )
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
