import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  /* height: 80%; */
  /* min-width: 68.75rem; */
  /* min-height: 32.1875rem; */
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 2px 8px 12px rgba(0,0,0,0.9);
  margin: auto;
  background: #fff;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-100);
  height: 4rem;
  padding: 1rem;
  color: var(--gray-750);

  h2 {
    font-weight: 400;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    border-radius: 5px;

    svg {
      width: 2rem;
      height: 2rem;
      color: var(--gray-200);
      transition: all 0.2s;

      &:hover {
        color: var(--gray-750);
        transform: rotate(90deg);
      }

      &:active {
        color: var(--gray-200);
        transform: rotate(180deg);
      }
    }
  }
`;

export const BodyModal = styled.div`
  display: flex;
  height: 100%;
  padding: 2rem;
  background: var(--blue-100);
`;



