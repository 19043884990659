import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  padding: 0 1.5rem;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-800);
  background-color: var(--gray-1);
  border-bottom: 1px solid #D1DDF1;
  width: 100%;

  span {
    font-size: 1rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  /* a {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    transition: background-color 0.2s;

    &:hover {
      outline: 1px solid #006E98;
    }

    &:active {
      background-color: #006EBF;
    }
  } */
`;
