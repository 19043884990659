import { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import imgDefault from '../../assets/img-icon.jpeg';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface IProps {
  id: string;
  title: string;
  subtitle: string;
  image: string;
  message: string;
  created_at: Date
}

export const Notifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState<IProps[]>([]);
  const [searchText, setSearchText] = useState('');
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/notifications', { timeout: 5000 }).then(response => {
        setNotifications(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast]);

  const handleSearch = useCallback(async (e) => {
    if(e){
      if (e.key === 'Enter') {
        setIsLoading(true);

        try {
          const response = await api.get(`/notifications?title=${searchText}`, { timeout: 5000 });
          setNotifications(response.data);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          addToast({
            type: 'error',
            title: 'Erro nos dados',
            description: '' + err,
          });
        }
      }
    }
  }, [addToast, searchText]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Notificações' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input name='name' type="search" placeholder='Pesquisar pelo título' onKeyPress={(e) => { handleSearch(e); setSearchText(e.currentTarget.value) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/notifications/create'>
                <FiEdit />
                <span>Criar notificação</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {notifications.length > 0 ?
                      <tr>
                        <th>Título</th>
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      notifications && notifications.map((notification: IProps) =>
                        <tr key={notification.id}>
                          <td>
                            <img src={notification.image ? notification.image : imgDefault} alt={notification.title} />
                            <span>{notification.title}<br />{notification.subtitle}<br /><small>{notification.message}</small></span>
                          </td>
                          <td>
                            <span>{new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(notification.created_at)).replace(',', ' às')}</span>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
