import React from 'react';
import {
  RouteProps,
  Route as ReactDOMRoute,
  Redirect
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface IRouteProps extends RouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/signin' : '/dashboard', state: { from: location } }} />
        )
      }}
    />
  );
}

export default Route;
