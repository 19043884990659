import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn, imgDescriptionReturn } from '../../components/MediaCenterModalContent';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading
} from './styles';

export const ProgramCreate = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('')

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome é obrigatório!'),
        about: Yup.string().required('Sobre é obrigatório!'),
        imgcover: Yup.string(),
      });

      let formData = {
        name: event.target['name'].value,
        about: event.target['about'].value,
        imgcover: event.target['imgcover'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.post('/radioprograms', formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Programa criado',
          description: 'O programa foi criado com sucesso.'
        });
        setIsLoading(false);
        history.push('/programs',);
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar programa',
        description: 'Não foi possível criar o programa. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast]);

  const handleOpenModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar Programa' />
          <Body>
            <HeaderBody>
              <Link to="/programs"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <Input name='name' type='text' placeholder='Nome' />
              <Input name='about' type='text' placeholder='Sobre' />

              <div className='imgContainer'>
                <span>Imagem de capa</span>
                <div className='imgContent'>
                  {
                    imgUrlReturn ?
                      <img src={imgUrlReturn} width={320} alt={imgDescriptionReturn} onClick={() => { handleOpenModal('media-selector') }} /> :
                      <img src={imgDefault} width={320} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector') }} />
                  }
                  <div className='inputsContent'>
                    <Input name='imgcover' type='text' placeholder='URL de imagem de capa' defaultValue={imgUrlReturn && imgUrlReturn} />
                  </div>
                </div>
              </div>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
        {isModalVisible ? <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal> : null}
      </Container>
    </>
  )
}
