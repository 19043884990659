import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import { FiSave, FiArrowLeft, FiLoader, FiEye, FiEyeOff, FiTag } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
// import { useAuth } from '../../hooks/auth'

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn, imgDescriptionReturn, imgCreditsReturn } from '../../components/MediaCenterModalContent';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  HeaderRightContent,
  ButtonPublish,
  ButtonUnpublish,
  ButtonFeature,
  FormContainer,
  Select,
  Input,
  // TextArea,
  Footer,
  ButtonSave,
  Loading
} from './styles';

interface IEditorShip {
  id: string;
  name: string;
  url: string;
}

interface INewsProps {
  id: string;
  editorship: IEditorShip;
  title: string;
  subtitle: string;
  slug: string;
  imgcover: string;
  imgcoverinfo: string;
  text: string;
  active: boolean;
  user: UserProps;
  created_at: Date;
  updated_at: Date;
  publication_at: Date;
}

interface UserProps {
  name: string;
  avatar: string;
  group: GroupProps;
}

interface GroupProps {
  id: string;
  name: string;
}

// function slugGenerate(title: string) {
//   const titleSlug = title
//     .toLowerCase()
//     .trimStart()
//     .trimEnd()
//     .normalize('NFD')
//     // eslint-disable-next-line no-useless-escape
//     .replace(/[\u0300-\u036f]/g, '').replace(/[^\w\-]+/g, '-');

//   return titleSlug;
// }

export const NewsEdit = () => {
  const { id }: any = useParams();
  // const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [editorShipList, setEditorShipList] = useState<IEditorShip[]>([]);
  const [slug, setSlug] = useState('');
  const [news, setNews] = useState<INewsProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('');
  const [imgCover, setImgCover] = useState('');
  const [imgCoverDescription, setImgCoverDescription] = useState('');
  // const [userGroup, setUserGroup] = useState<UserProps>();

  // useEffect(() => {
  //   api.get(`/users/details/${user.id}`).then(response => {
  //     setUserGroup(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       history.push('/signout');
  //     }
  //   });
  // }, [user.id, history]);

  useEffect(() => {
    const editorShipGet = async () => {
      try {
        setIsLoading(true);

        const response = await api.get('/editorships/admin');
        setEditorShipList(response.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar Editorias',
          description: 'Não foi possível carregar as editorias. Contate o administrador.',
        });
      }
    }

    editorShipGet();
  }, [addToast]);

  useEffect(() => {
    setIsLoading(true);

    try {
      api.get(`/news/edit/${id}`, { timeout: 5000 }).then(response => {
        setNews(response.data);
        setImgCover(response.data.imgcover);
        setImgCoverDescription(response.data.imgcoverinfo);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }

    setSlug(news?.slug || '');
  }, [addToast, id, news?.slug]);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        editorship: Yup.string().required('A Editoria é obrigatória!'),
        title: Yup.string().required('O Título é obrigatório!').min(25, 'O Título deve ter pelo menos 25 caracteres').max(80, 'O Título deve ter no máximo 80 caracteres.'),
        subtitle: Yup.string().required('O Subtítulo é obrigatório!').min(25, 'O subtítulo deve ter pelo menos 25 caracteres').max(200, 'O Subtítulo deve ter no máximo 200 caracteres.'),
        imgcover: Yup.string(),
        imgcoverinfo: Yup.string(),
        slug: Yup.string(),
        text: Yup.string().required('O Texto da notícia é obrigatório!')
      });

      let formData = {
        editorship: event.target['editorship'].value,
        title: event.target['title'].value,
        subtitle: event.target['subtitle'].value,
        slug: event.target['slug'].value,
        imgcover: event.target['imgcover'].value,
        imgcoverinfo: event.target['imgcoverinfo'].value,
        text: event.target['newsText'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/news/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'A notícia foi modificada com sucesso.'
        });
        setIsLoading(false);
        history.push('/news',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar notícia',
        description: 'Não foi possível salvar a notícia. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);

  const handleSetBreakingnews = async () => {
    const data = {
      news_id: id,
    }

    const answer = window.confirm('Deseja realmente definir como Destaque?');

    if (answer) {
      try {
        await api.post('/breakingnews', data, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Notícia Destaque difinida com sucesso.',
        });
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao definir a novo Destaque' + err,
        });
      }
    }
  }

  const handlePublishNews = async () => {
    // const data = {
    //   news_id: id,
    // }

    const answer = window.confirm('Deseja realmente Publicar a Notícia?');

    if (answer) {
      try {
        await api.patch(`/news/publication/${id}/publish`, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Notícia Publicada com sucesso.',
        });
        history.push('/news')
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao Publicar Notícia' + err,
        });
      }
    }
  }

  const handleUnpublishNews = async () => {
    // const data = {
    //   news_id: id,
    // }

    const answer = window.confirm('Deseja realmente Despublicar a Notícia?');

    if (answer) {
      try {
        await api.patch(`/news/publication/${id}/unpublish`, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Notícia Despulicada com sucesso.',
        });
        history.push('/news')
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao Despublicada Notícia' + err,
        });
      }
    }
  }

  const handleOpenModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    if (imgUrlReturn && imgDescriptionReturn && imgCreditsReturn) {
      setImgCover(imgUrlReturn);
      setImgCoverDescription(`${imgDescriptionReturn} (Foto: ${imgCreditsReturn})`);
    }
    setIsModalVisible(false);
  }, []);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar Notícia' />
          <Body>
            <HeaderBody>
              <Link to="/news"><FiArrowLeft /> Voltar</Link>
              <HeaderRightContent>
                {
                  news?.active ?
                    <ButtonUnpublish onClick={handleUnpublishNews}><FiEyeOff />Despublicar notícia</ButtonUnpublish> :
                    <ButtonPublish onClick={handlePublishNews}><FiEye />Publicar notícia</ButtonPublish>
                }
                <ButtonFeature onClick={handleSetBreakingnews}><FiTag />Definir como destaque</ButtonFeature>
              </HeaderRightContent>
            </HeaderBody>
            {
              isLoading && <h3>Carregando...</h3>
            }
            {
              news &&
              <FormContainer onSubmit={handleSubmit}>
                <Select name="editorship">
                  {
                    editorShipList.map((editorShip: IEditorShip) => {
                      if (editorShip.id === news?.editorship.id) {
                        return <option key={editorShip.id} value={editorShip.id} selected>{editorShip.name}</option>
                      } else {
                        return <option key={editorShip.id} value={editorShip.id}>{editorShip.name}</option>
                      }
                    })
                  }
                </Select>
                <Input name='title' defaultValue={news?.title} type='text' placeholder='Título' onBlur={()=>{alert('O Slug não será alterado automaticamente para preservar a URL da postagem.')}} required minLength={25} maxLength={80} />
                {/* <Input name='title' defaultValue={news?.title} type='text' placeholder='Título' onChange={(e) => setSlug(slugGenerate(e.currentTarget.value))} required minLength={50} maxLength={150} /> */}
                <Input name='subtitle' defaultValue={news?.subtitle} type='text' placeholder='Subtítulo' required minLength={25} maxLength={200} />
                <Input name='slug' type='text' placeholder='Slug' defaultValue={slug} required />
                {/* <TextArea name='newsText' placeholder='Texto da notícia' rows={24} defaultValue={news?.text}></TextArea> */}
                <Editor
                  textareaName='newsText'
                  apiKey={process.env.REACT_APP_TINY_API_KEY}
                  initialValue={news?.text}
                  init={{
                    // height: 500,
                    menubar: 'edit view insert format tools table',
                    menu: {
                      edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                      view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                      insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                      format: { title: 'Format', items: 'bold italic underline fontsize | formats blockformats align lineheight | forecolor backcolor | removeformat' },
                      tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                      table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                      help: { title: 'Help', items: 'help' }
                    },
                    plugins: [
                      'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'blockquote',
                      'fullscreen', 'insertdatetime', 'media', 'table', 'wordcount', 'emoticons', 'code'
                      // 'fullscreen', 'insertdatetime', 'media', 'table', 'wordcount', 'emoticons', 'quickbars', 'quickimage', 'code'
                    ],
                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                      'blockquote alignleft aligncenter alignright alignjustify | ' +
                      'bullist numlist checklist outdent indent | removeformat | table | link | image | media | emoticons',
                    media_live_embeds: true,
                  }}
                // value={text}
                // onEditorChange={(newValue) => setText(newValue)}
                />

                <div className='imgContainer'>
                  <span>Imagem de capa</span>
                  <div className='imgContent'>
                    {
                      news?.imgcover ?
                        <img src={imgCover} width={320} alt={imgCoverDescription} onClick={() => { handleOpenModal('media-selector') }} /> :
                        <img src={imgDefault} width={320} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector') }} />
                    }
                    <div className='inputsContent'>
                      <label htmlFor="imgcover">URL da Imagem</label>
                      <Input name='imgcover' defaultValue={imgCover} type='text' placeholder='URL da Imagem' />
                      <label htmlFor="imgcoverinfo">Descrição</label>
                      <Input name='imgcoverinfo' defaultValue={imgCoverDescription} type='text' placeholder='Descrição da imagem de capa' />
                    </div>
                  </div>
                </div>
                <div className='infosAbout'>
                  <div className='infosAuthorContent'>
                    <span><strong>Autor:</strong></span>
                    <div className='infosAuthor'>
                      <img src={news?.user.avatar} alt={news?.user.name} style={{borderRadius: '50%', width: 28, height: 28}} />{news?.user.name}
                    </div>
                  </div>
                  <div className='infosCreatedAtContent'>
                    <span><strong>Criado em:</strong></span>
                    <div className='infosCreatedAt'>
                      {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(news.created_at)).replace(',', ' às')}
                    </div>
                  </div>
                  {
                    news.publication_at &&
                    <div className='infosCreatedAtContent'>
                      <span><strong>Publicado em:</strong></span>
                      <div className='infosCreatedAt'>
                        {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(news.publication_at)).replace(',', ' às')}
                      </div>
                    </div>
                  }
                  <div className='infosCreatedAtContent'>
                    <span><strong>Última atualização:</strong></span>
                    <div className='infosCreatedAt'>
                      {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(news.updated_at)).replace(',', ' às')}
                    </div>
                  </div>
                </div>
                <Footer>
                  <ButtonSave type='submit' disabled={isLoading}>
                    {isLoading ? <Loading><FiLoader /></Loading> : <><FiSave /><span>Salvar notícia</span></>}
                  </ButtonSave>
                </Footer>
              </FormContainer>
            }
          </Body>
        </Content>
        {isModalVisible ? <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal> : null}
      </Container>
    </>
  )
}
