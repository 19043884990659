import { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';


import {
  Container,
  Content,
  Body,
  ListContent,
} from './styles';

interface IEditorShipProps {
  url: string;
  name: string;
}

interface INewsProps {
  id: string;
  title: string;
  slug: string;
  imgcover: string;
  editorship: IEditorShipProps;
}

interface ITopFiveNewsProps {
  id: string;
  top_position: number;
  news_id: string;
  news: INewsProps;
}

export const TopFiveNews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [topFiveNews, setTopFiveNews] = useState<ITopFiveNewsProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/topfivenews', { timeout: 5000 }).then(response => {
        setTopFiveNews(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }

  }, [addToast]);

  const handleUpdateTopFiveNews = useCallback(async (e, id: string, topposition: number, news_id: string) => {

    if (e.key === 'Enter') {

    const answer = window.confirm(`Deseja realmente alterar?`);

    const data = {
      "top_position": Number(topposition),
	    "news_id": news_id
    }

      if (answer) {
        setIsLoading(true);

        try {
          await api.put(`/topfivenews/admin/update/${id}`, data, { timeout: 5000 });
          setIsLoading(false);
          document.location.reload();
        } catch (err) {
          setIsLoading(false);
          addToast({
            type: 'error',
            title: 'Erro nos dados',
            description: '' + err,
          });
        }
      }
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Top 5' />
          <Body>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {topFiveNews.length > 0 ?
                      <tr>
                        <th></th>
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h1>1</h1>
                        <div className='spanAndInputContainer'>
                          <span>{topFiveNews[0].news.title}</span>
                          <div className='inputButtonContainer'>
                            <input type="text" defaultValue={topFiveNews[0].news_id} onKeyPress={(e)=>{handleUpdateTopFiveNews(e, topFiveNews[0].id, 1, e.currentTarget.value)}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h1>2</h1>
                        <div className='spanAndInputContainer'>
                          <span>{topFiveNews[1].news.title}</span>
                          <div className='inputButtonContainer'>
                            <input type="text" defaultValue={topFiveNews[1].news_id} onKeyPress={(e)=>{handleUpdateTopFiveNews(e, topFiveNews[1].id, 2, e.currentTarget.value)}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h1>3</h1>
                        <div className='spanAndInputContainer'>
                          <span>{topFiveNews[2].news.title}</span>
                          <div className='inputButtonContainer'>
                            <input type="text" defaultValue={topFiveNews[2].news_id} onKeyPress={(e)=>{handleUpdateTopFiveNews(e, topFiveNews[2].id, 3, e.currentTarget.value)}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h1>4</h1>
                        <div className='spanAndInputContainer'>
                          <span>{topFiveNews[3].news.title}</span>
                          <div className='inputButtonContainer'>
                            <input type="text" defaultValue={topFiveNews[3].news_id} onKeyPress={(e)=>{handleUpdateTopFiveNews(e, topFiveNews[3].id, 4, e.currentTarget.value)}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h1>5</h1>
                        <div className='spanAndInputContainer'>
                          <span>{topFiveNews[4].news.title}</span>
                          <div className='inputButtonContainer'>
                            <input type="text" defaultValue={topFiveNews[4].news_id} onKeyPress={(e)=>{handleUpdateTopFiveNews(e, topFiveNews[4].id, 5, e.currentTarget.value)}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
