import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

// import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  // ButtonFeature,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading
} from './styles';

interface IMediaProps {
  id: string;
  description: string;
  credits: string;
  url: string;
}

export const MediaEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [media, setMedia] = useState<IMediaProps>();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    try {
      setIsLoading(true);
      api.get(`/medias/edit/${id}`, { timeout: 5000 }).then(response => {
        setMedia(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, id]);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        description: Yup.string().required('A descrição é obrigatória porcodio!'),
        credits: Yup.string().required('O crédito e direito autoral também é obrigatório!'),
      });

      let formData = {
        description: event.target['description'].value,
        credits: event.target['credits'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/medias/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'A mídia foi alterada com sucesso.'
        });
        setIsLoading(false);
        history.push('/mediacenter');
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar mídia',
        description: 'Não foi possível salvar a mídia. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar Mídia' />
          <Body>
            <HeaderBody>
              <Link to="/mediacenter"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <Input name='description' defaultValue={media?.description} type='text' placeholder={isLoading ? 'Carregando...' : 'Descrição'} readOnly={isLoading} />
              <Input name='credits' defaultValue={media?.credits} type='text' placeholder={isLoading ? 'Carregando...' : 'Créditos ou Direitos Autorais'} readOnly={isLoading} />
              {/* <img src={imgDefault} alt="Imagem" /> */}
              {/* <img src={media?.url} alt={media?.description} /> */}
              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar mídia</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
