import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import Menu from '../../components/Menu';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import {
  Container,
  Content,
  Body,
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  email: string;
  group: string;
  avatar: string;
}

// interface INewsProps {
//   created_at: Date;
//   editorship: IEditorShipProps;
// }

// interface IEditorShipProps {
//   name: string;
//   url: string;
// }

export const Dashboard = () => {
  const history = useHistory();
  const [users, setUsers] = useState<IUserProps[]>([]);
  // const [news, setNews] = useState<INewsProps[]>([]);
  // const [medias, setMedias] = useState([]);
  // const [columnists, setColumnists] = useState([]);
  // const [publications, setPublications] = useState([]);
  // const [editorShips, setEditorShips] = useState<IEditorShipProps[]>([]);
  // const [radioPrograms, setRadioPrograms] = useState([]);
  const { addToast } = useToast();

  useEffect(() => {
    api.get('/users', { timeout: 5000 }).then(response => {
      setUsers(response.data);
    }).catch(err => {
      if (err.message === 'Request failed with status code 401') {
        addToast({
          type: 'error',
          title: 'Autenticação',
          description: 'Você precisa estar autenticado!',
        });
        history.push('/signout');
      }
    });
  }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/news/admin/all/count', { timeout: 5000 }).then(response => {
  //     setNews(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/medias/admin/all', { timeout: 5000 }).then(response => {
  //     setMedias(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/publications', { timeout: 5000 }).then(response => {
  //     setPublications(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/editorships', { timeout: 5000 }).then(response => {
  //     setEditorShips(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/columnists', { timeout: 5000 }).then(response => {
  //     setColumnists(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/columnists', { timeout: 5000 }).then(response => {
  //     setColumnists(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  // useEffect(() => {
  //   api.get('/radioprograms', { timeout: 5000 }).then(response => {
  //     setRadioPrograms(response.data);
  //   }).catch(err => {
  //     if (err.message === 'Request failed with status code 401') {
  //       addToast({
  //         type: 'error',
  //         title: 'Autenticação',
  //         description: 'Você precisa estar autenticado!',
  //       });
  //       history.push('/signout');
  //     }
  //   });
  // }, [addToast, history]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Dashboard' />
          <Body>
            {/* <div className='dashboardItemContainer'>

              <div className='dashboardItemContent' onClick={() => { history.push('/news') }}>
                <div className='itemHeader'>Notícias</div>
                <div className='itemBody'>{news.length}</div>
              </div>

              <div className='dashboardItemContent' onClick={() => { history.push('/mediacenter') }}>
                <div className='itemHeader'>Mídias</div>
                <div className='itemBody'>{medias.length}</div>
              </div>

              <div className='dashboardItemContent' onClick={() => { history.push('/columnists') }}>
                <div className='itemHeader'>Colunistas</div>
                <div className='itemBody'>{columnists.length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Colunas publicadas</div>
                <div className='itemBody'>{publications.length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Editorias</div>
                <div className='itemBody'>{editorShips.length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Usuários</div>
                <div className='itemBody'>{users.length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Programas de rádio</div>
                <div className='itemBody'>{radioPrograms.length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Comentários</div>
                <div className='itemBody'>0</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Acessos</div>
                <div className='itemBody'>0</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Compartilhamentos</div>
                <div className='itemBody'>0</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Saúde</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'saude').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Economia</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'economia').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Trânsico</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'transito').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Segurança</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'seguranca').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Política</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'politica').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Esporte</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'esporte').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Agronegócio</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'agronegocio').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Pinhalzinho</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'pinhalzinho').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Educação</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'educacao').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Brasil</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'brasil').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Entretenimento</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'entretenimento').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Cultura</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'cultura').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Meio Ambiente</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'meio-ambiente').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Curiosidade</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'curiosidade').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Mundo</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'mundo').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Tecnologia</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'tecnologia').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Ciência</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'ciencia').length}</div>
              </div>

              <div className='dashboardItemContent'>
                <div className='itemHeader'>Notícias de Justiça</div>
                <div className='itemBody'>{news.filter(n => n.editorship.url === 'justica').length}</div>
              </div>

            </div> */}
          </Body>
        </Content>
      </Container>
    </>
  )
}
