import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  transform: color 0.2s;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--gray-200);
    }
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  input[type="file"] {
    display: none;
  }

  img {
    margin-top: 1rem;
    max-width: 832px;
  }

  .labelFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* min-height: 468px; */
    /* border: 1px solid #9E94FB; */
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    margin: auto;
    gap: 1rem;

    &:hover {
      border: 4px solid #9E94FB;
    }

    &:active {
      border: 1px solid #9E94FB;
    }
  }
`;

export const Input = styled.input`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;

  input {
      min-width: 27.5rem;
      padding: 0.5rem;
      border-radius: 5px;
      outline: none;
      border: 1px solid #9E94FB;
      margin-bottom: 1rem;
    }


`;

export const Footer = styled.div`
  display: flex;
  align-items: cente;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-top: 1px solid #D1DDF1;
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: #fff;
  padding: 0.875rem;
  border-radius: 5px;
  margin-top: 1rem;
  transition: background-color 0.2s;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;
  font-size: 4rem;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
