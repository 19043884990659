import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  .tox-statusbar__branding {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  /* background: #fff; */
  margin: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;

  .tox-tinymce {
    border: 1px solid #D1DDF1;
    border-radius: 5px;
    min-height: 540px;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  transform: color 0.2s;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--gray-200);
    }
  }

  .buttonsContainer {
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border: none;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      background: #006EBF;
      color: #fff;

      &:hover {
        background: #258dd9;
      }

      &:active {
        background: #61b2ed;
      }
    }
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ButtonFeature = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #45CB31;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.75rem 1.5rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3AAE28;
  }

  @media (max-width: 648px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .imgContainer {
    display: flex;
    margin-top: 3rem;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-left: 0.25rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-top: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);


    span {
      font-size: 1.5rem;
    }

    .imgContent {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      width: 100%;

      img {
        border-radius: 4px;
        outline: 2px solid var(--gray-200);
        border: 2px solid #fff;
        opacity: 1;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          opacity: 0.5;
        }
        &:active {
          opacity: 0.1;
        }
      }

      .inputsContent {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        width: 100%;

        @media (max-width: 648px) {
          margin-left: 0;
          margin-top: 1rem;
        }
      }

      @media (max-width: 648px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }
    }
  }
`;

export const Select = styled.select`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const TextArea = styled.textarea`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
  resize: vertical;
`;

export const Footer = styled.div`
  display: flex;
  align-items: cente;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: #fff;
  padding: 0.875rem;
  border-radius: 5px;
  margin-top: 1rem;
  transition: background-color 0.2s;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
