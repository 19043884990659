import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn } from '../../components/MediaCenterModalContent';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Select,
  Footer,
  ButtonSave,
  Loading,
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  email: string;
  avatar: string;
  group: IGroupProps;
  active: boolean;
}

interface IGroupProps {
  id: string;
  name: boolean;
}

export const UserEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [user, setUser] = useState<IUserProps>();
  const [groups, setGroups] = useState<IGroupProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('');
  const [imgAvatar, setImgAvatar] = useState('');

  useEffect(() => {
    setIsLoading(true);
    try {
      api.get(`/users/details/${id}`, { timeout: 5000 }).then(response => {
        setUser(response.data);
        setImgAvatar(response.data.avatar);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, id]);

  useEffect(() => {
    setIsLoading(true);
    api.get('/groups').then(response => {
      setGroups(response.data);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome é obrigatório!'),
        email: Yup.string().required('E-mail é obrigatório!'),
        group: Yup.string().required('Grupo é obrigatório!'),
        active: Yup.boolean(),
      });

      let formData = {
        name: event.target['name'].value,
        email: event.target['email'].value,
        group: event.target['group'].value,
        avatar: event.target['avatar'].value,
        active: event.target['active'].checked,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/users/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'O usuário foi salvo com sucesso.'
        });
        setIsLoading(false);
        history.push('/users',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Não foi possível salvar o usuário. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);

  const handleOpenModal = useCallback((_modalId: string, modalopt: boolean) => {
    setModalId(_modalId);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    if (imgUrlReturn) {
      setImgAvatar(imgUrlReturn);
    }
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar Usuário' />
          <Body>
            <HeaderBody>
              <Link to="/users"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            {
              isLoading && <h3>Carregando...</h3>
            }
            <FormContainer onSubmit={handleSubmit}>
              <Input name='name' defaultValue={user?.name} type='text' placeholder='Nome' />
              <Input name='email' defaultValue={user?.email} type='text' placeholder='E-mail' />
              <Select name='group'>
                {
                  groups && groups.map((group: IGroupProps) => {
                    if (group.id === user?.group.id) {
                      return <option key={group.id} value={group.id} selected>{group.name}</option>
                    } else {
                      return <option key={group.id} value={group.id}>{group.name}</option>
                    }
                  })
                }
              </Select>
              <label htmlFor="active">Ativo</label>
              <input name='active' defaultChecked={user?.active} type={'checkbox'} />
              <div className='imgContainer'>
                <span>Avatar</span>
                <div className='imgContent'>
                  {
                    imgAvatar ?
                      <img src={imgAvatar} width={180} alt={user?.name} onClick={() => { handleOpenModal('media-selector', false) }} /> :
                      <img src={imgDefault} width={180} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector', false) }} />
                  }
                  <div className='inputsContent'>
                    <label htmlFor="avatar">URL da Imagem</label>
                    <Input name='avatar' defaultValue={imgAvatar} type='text' placeholder='URL da Imagem' />
                  </div>
                </div>
              </div>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
        {isModalVisible ? <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal> : ''}
      </Container>
    </>
  )
}
