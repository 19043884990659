import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  /* background: #fff; */
  margin: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  transform: color 0.2s;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--gray-200);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .userName {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
`;

export const Input = styled.input`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: #fff;
  padding: 0.875rem;
  border-radius: 5px;
  transition: background-color 0.2s;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
