import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiEdit, FiEyeOff } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import imgDefault from '../../assets/img-icon.jpeg';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface INews {
  id: string;
  title: string;
  imgcover: string;
  user: IUserProps;
  created_at: Date;
  updated_at: Date;
  publication_at: Date;
  active: boolean;
  editorship: IEditorShipProps;
}

interface IUserProps {
  name: string;
  avatar: string;
  group: IGroupProps;
}

interface IGroupProps {
  name: string;
}

interface IBreakingNews {
  news: INews;
}

interface IEditorShipProps {
  id: string;
  name: string;
  url: string;
}

export const News = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState<INews[]>([]);
  const [breakingNews, setBreakingNews] = useState<IBreakingNews>();
  const { addToast } = useToast();
  // const toDay = new Date();

  useEffect(() => {
    try {
      api.get('/breakingnews/admin/all', { timeout: 10000 }).then(response => {
        setBreakingNews(response.data);
      }).catch(err => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      })

      api.get('/news/admin/all', { timeout: 10000 }).then(response => {
        setNews(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
        if (err.message === 'Request failed with status code 401') {
          history.push('/signout');
        }
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, history]);


  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const textSearch = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/news/admin/all?title=${textSearch}`, { timeout: 10000 });
        setNews(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const deleteNews = useCallback(async (id: string) => {

    const answer = window.confirm('Deseja realmente Excluir a Notícia?');

    if (answer) {
      setIsLoading(true);

      try {
        await api.delete(`/news/delete/${id}`, { timeout: 10000 });
        setIsLoading(false);
        document.location.reload();
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Notícias' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input type="search" placeholder='Pesquisar pelo título' onKeyPress={(e) => { handleSearch(e) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/news/create'>
                <FiEdit />
                <span>Criar notícia</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {news.length > 0 ?
                      <tr>
                        <th>Título</th>
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    <tr key={breakingNews?.news.id}>
                      <td className='breakingnews'>
                        <div className='leftContent'>
                          {!breakingNews?.news.active && <FiEyeOff style={{ marginRight: '1rem' }} />}
                          {breakingNews?.news.imgcover ? <img src={breakingNews?.news.imgcover} alt="" /> : <img src={imgDefault} alt='Imagem Padrão' />}
                          <span><Link to={`/news/edit/${breakingNews?.news.id}`}>{breakingNews?.news.title}</Link></span>
                          <h4>Destaque</h4>
                        </div>
                        <div className='author'>
                          <img src={breakingNews?.news.user.avatar} alt={breakingNews?.news.user.name} title={breakingNews?.news.user.name} />
                        </div>
                      </td>
                    </tr>
                    {
                      news && news.map((newsItem: INews) =>
                        <tr key={newsItem.id} onDoubleClick={() => { deleteNews(newsItem.id) }}>
                          <td>
                            <div className='leftContent'>
                              {!newsItem.active && <FiEyeOff style={{ marginRight: '1rem' }} />}
                              {newsItem.imgcover ? <img src={newsItem.imgcover} alt={newsItem.title} /> : <img src={imgDefault} alt='Imagem Padrão' />}
                              <span>
                                <Link to={`/news/edit/${newsItem.id}`}>
                                  <div className='titleText'>{newsItem.title}</div>
                                  <div style={{display: 'flex', gap: '1rem' }}>
                                    <small>Criado em: {new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(newsItem.created_at))}</small>
                                    {newsItem.publication_at && <small>Publicado em: {new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(newsItem.publication_at))}</small>}
                                    <small>Editoria: {newsItem.editorship.name}</small>
                                  </div>
                                </Link>
                              </span>
                            </div>
                            <div className='author'>
                              <img src={newsItem.user.avatar} alt={newsItem.user.name} title={newsItem.user.name} />
                            </div>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
