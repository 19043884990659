import { ChangeEvent, useCallback, useState } from "react";

// import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
// import 'filepond/dist/filepond.min.css'

// // `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import api from "../../services/api";

import {
  Container,
  Content,
  Body,
} from './styles';

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

interface FileProps {
  id: string;
  description: string;
  credits: string;
  url: string;
  filename: string;
  storage: string;
}

export const UploadMedia = () => {
  const [description, setDescription] = useState('');
  const [credits, setCredits] = useState('');
  const [file, setFile] = useState<FileProps>();

  const handleFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const dataForm = new FormData();

    if (description === '') {
      alert('Escreva uma descrição');
      return;
    }

    if (credits === '') {
      alert('Escreva os créditos');
      return;
    }

    if (e.target.files) {
      dataForm.append('description', description);
      dataForm.append('credits', credits);
      dataForm.append('file', e.target.files[0]);
      const response = await api.post('medias/upload', dataForm);
      console.log(response.data);
      setFile(response.data);
    }
  }, [description, credits]);

  return (
    <>
      <Container>
        <Content>
          <Body>
            <form>
              <label htmlFor="description">Descrição</label>
              <input type="text" name="description" id="description" placeholder="Escreva uma descrição única para a imagem" autoComplete="off" autoCorrect="off" onChange={(e) => { setDescription(e.target.value) }} />

              <label htmlFor="credits">Crédito</label>
              <input type="text" name="credits" id="credits" placeholder="IMPORTANTE! Informe os créditos ou direitos autorais" autoComplete="off" autoCorrect="off" onChange={(e) => { setCredits(e.target.value) }} />

              {
                file ? <>
                  <label htmlFor="credits">Imagem enviada</label>
                  <img src={file.url} alt={file.filename} />
                  <label htmlFor="url">Copie a URL abaixo</label>
                  <input type="text" name="url" id="url" value={file.url} readOnly />
                </> : <>
                  <label htmlFor="file" className="labelFile"><h4>Clique para enviar imagem</h4><br />Especificações:<br />Resolução máxima 1200x748 pixels<br />600 KBytes</label>
                  <input type="file" name="file" id="file" onChange={handleFileChange} accept=".gif,.jpg,.jpeg,.png,.mp3,.mp4,.ogg" />
                </>
              }
            </form>
            {/* <FilePond
              // files={files}
              allowMultiple={true}
              // onupdatefiles={() => { setFiles([]) }}
              labelIdle='<strong>Arraste e solte aqui seus arquivos ou navegue pelo computador</strong>'
            /> */}
          </Body>
        </Content>
      </Container>
    </>
  )
}
