import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;

  .dashboardItemContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .dashboardItemContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 250px;
    min-width: 250px;
    /* max-width: 250px; */
    height: 120px;
    border-radius: 8px;
    background: #fff;
    border: 2px solid #6666DA;
    overflow: hidden;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
    cursor: pointer;

    &:hover {
      box-shadow: 4px 4px 8px rgba(0,0,0,0.2);
    }

    .itemHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      background: #6666DA;
      width: 100%;
      color: #fff;
    }

    .itemBody {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: #fff;
      color: #6666DA;
      font-weight: 700;
      font-size: 3rem;
    }

    .itemFooter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 64px;
      background: var(--gray-100);
    }
  }
`;
