import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiPlus, FiEdit, FiShare, FiShare2 } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface ISurveyQuestionProps {
  id: string;
  question: string
  _count: {
    SurveyAnswer: number
  }
}

interface ISurveyProps {
  id: string;
  title: string;
  description: string;
  active: boolean;
  public_view: boolean;
  expiration_date: Date;
  SurveyQuestion: ISurveyQuestionProps[];
  _count: {
    SurveyAnswer: number;
    SurveyQuestion: number;
  }
}

export const Surveys = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState<ISurveyProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/surveys?id=', { timeout: 5000 }).then(response => {
        setSurveys(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      // const busca = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/surveys?id=`, { timeout: 5000 });
        setSurveys(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  function handleCopyToClipBoardSurvey(_surveyid: string, _what: string){
    if(_what === 'survey') {
      navigator.clipboard.writeText(`https://enquete.rco.com.br/${_surveyid}`)
    }else if(_what === 'charts') {
      navigator.clipboard.writeText(`https://enquete.rco.com.br/${_surveyid}/charts`)
    }else{
      navigator.clipboard.writeText(`https://enquete.rco.com.br/${_surveyid}/view`)
    }

    addToast({
      type: 'success',
      title: 'Endereço da enquete copiado.',
      description: '',
    });
  }

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Enquetes' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input name='name' type="search" placeholder='Pesquisar pelo título' onKeyPress={(e) => { handleSearch(e) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/surveys/create'>
                <FiPlus />
                <span>Criar enquete</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                surveys.length > 0 && surveys.map(survey => (
                    <div key={survey.id} className='cardContainer'>
                      <div className='cardTitle'>
                        {survey.title}
                      </div>
                      <div className='cardActive'>
                        A enquete está {survey.active ? 'ativa' : 'inativa'}
                      </div>
                      <div className='cardPublicView'>
                        A enquete é {survey.public_view ? 'pública' : 'restrita'}
                      </div>
                      <div className='cardPublicView'>
                        Expira em {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).format(new Date(survey.expiration_date))}
                      </div>
                      <div className='cardQuestionContainer'>
                        {
                          survey.SurveyQuestion.map(question => (
                            <div className='cardQuestion' key={question.id}>
                              <div>{question.question}</div>
                              <div><small>Votos: {question._count.SurveyAnswer}</small> - {parseFloat((100 * question._count.SurveyAnswer / survey._count.SurveyAnswer).toFixed(2)).toString().replace('.', ',')}%</div>
                            </div>
                          ))
                        }
                      </div>
                      <div className='cardFooter'>
                        <div className='footerItems'><FiEdit onClick={()=>{history.push(`/surveys/edit/${survey.id}`)}} title='Editar' /></div>
                        <div className='footerItems'><FiShare onClick={()=>{handleCopyToClipBoardSurvey(survey.id, 'charts')}} title='Compartilhar resultado' /></div>
                        <div className='footerItems'><FiShare2 onClick={()=>{handleCopyToClipBoardSurvey(survey.id, 'survey')}} title='Compartilhar enquete' /></div>
                      </div>
                    </div>
                ))
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
