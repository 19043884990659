import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader, FiArrowDown } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn, imgDescriptionReturn } from '../../components/MediaCenterModalContent';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  TextArea,
  Footer,
  ButtonSave,
  Loading
} from './styles';

export const NotificationCreate = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('')

  const [titleTxt, setTitleTxt] = useState('')
  const [messageTxt, setMessageTxt] = useState('')
  const [urlTxt, setUrlTxt] = useState('')
  const [imageTxt, setImageTxt] = useState('')
  const [imageImg, setImageImg] = useState(imgDefault)

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O Título é obrigatório!'),
        subtitle: Yup.string(),
        message: Yup.string(),
        image: Yup.string(),
        launch_url: Yup.string(),
      });

      let formData = {
        title: event.target['title'].value,
        subtitle: event.target['subtitle'].value,
        message: event.target['message'].value,
        image: event.target['image'].value,
        launch_url: event.target['launch_url'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.post('/notifications/admin/create', formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Notificação criada',
          description: 'A notificação foi criada com sucesso.'
        });
        setIsLoading(false);
        history.push('/notifications',);
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar notificação',
        description: 'Não foi possível criar a notificação. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast]);

  const handleOpenModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(false);
  }, []);

  function setRadarNotification() {
    setTitleTxt('RADAR AO VIVO')
    setMessageTxt('Aqui seu dia começa bem informado.')
    setUrlTxt('https://www.youtube.com/rcofm/live')
    setImageTxt('https://d20illa76ai7va.cloudfront.net/882bcf03602aaa9f82eb1e45e600d7.jpg');
    setImageImg('https://d20illa76ai7va.cloudfront.net/882bcf03602aaa9f82eb1e45e600d7.jpg')
  }

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar notificação' />
          <Body>
            <HeaderBody>
              <Link to="/notifications"><FiArrowLeft /> Voltar</Link>
              <div className='buttonsContainer'>
                <button name="radar" onClick={setRadarNotification}>Radar <FiArrowDown /></button>
              </div>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <Input name='title' type='text' placeholder='Title (Obrigatório)' defaultValue={titleTxt} required />
              <Input name='subtitle' type='text' placeholder='Subtítulo (apenas para iOS)(opcional)' />
              <TextArea name="message" id="" cols={30} rows={10} placeholder='Conteúdo da mensagem' defaultValue={messageTxt}></TextArea>
              <Input name='launch_url' type='text' placeholder='URL de destino (opcional)' defaultValue={urlTxt} />

              <div className='imgContainer'>
                <span>Imagem de capa</span>
                <div className='imgContent'>
                  {
                    imgUrlReturn ?
                      <img src={imgUrlReturn} width={320} alt={imgDescriptionReturn} onClick={() => { handleOpenModal('media-selector') }} /> :
                      <img src={imageImg} width={320} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector') }} />
                  }
                  <div className='inputsContent'>
                    <Input name='image' type='text' placeholder='URL de imagem de capa (opcional)' defaultValue={imgUrlReturn ? imgUrlReturn : imageTxt} />
                  </div>
                </div>
              </div>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
        {isModalVisible ? <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal> : null}
      </Container>
    </>
  )
}
