import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn, imgDescriptionReturn, imgCreditsReturn } from '../../components/MediaCenterModalContent';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Select,
  Input,
  Footer,
  ButtonSave,
  Loading
} from './styles';

function slugGenerate(title: string) {
  const titleSlug = title
    .toLowerCase()
    .trimStart()
    .trimEnd()
    .normalize('NFD')
    // eslint-disable-next-line no-useless-escape
    .replace(/[\u0300-\u036f]/g, '').replace(/[^\w\-]+/g, '-');

  return titleSlug;
}

interface IColumnistProps {
  id: string;
  name: string;
  slug: string;
}

export const PublicationCreate = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [columnists, setColumnists] = useState<IColumnistProps[]>([]);
  const [slug, setSlug] = useState('');
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('')


  useEffect(() => {
    const columnistGet = async () => {
      try {
        const response = await api.get('/columnists/admin');
        setColumnists(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar Colunistas',
          description: 'Não foi possível carregar as colunistas. Contate o administrador.',
        });
      }
    }

    columnistGet();
  }, [addToast]);


  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        columnist_id: Yup.string().required('O Colunista é obrigatório!'),
        title: Yup.string().required('O Título é obrigatório!').min(15, 'O Título deve ter pelo menos 15 caracteres').max(120, 'O Título deve ter no máximo 120 caracteres.'),
        subtitle: Yup.string().required('O Subtítulo é obrigatório!').min(15).max(200, 'O Subtítulo deve ter no máximo 200 caracteres.'),
        imgcover: Yup.string(),
        imgcoverinfo: Yup.string(),
        slug: Yup.string(),
        text: Yup.string().required('O Texto da notícia é obrigatório!')
      });

      let formData = {
        columnist_id: event.target['columnist_id'].value,
        title: event.target['title'].value,
        subtitle: event.target['subtitle'].value,
        slug: event.target['slug'].value,
        imgcover: event.target['imgcover'].value,
        imgcoverinfo: event.target['imgcoverinfo'].value,
        text: event.target['newsText'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.post('publications', formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Publicação Criada',
          description: 'A publicação foi criada com sucesso.'
        });
        setIsLoading(false);
        history.push(`/columnists}`);
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar publicação',
        description: 'Não foi possível criar a publicação. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast]);

  const handleOpenModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar Publicação' />
          <Body>
            <HeaderBody>
              <Link to="/columnists"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <Select name="columnist_id" required>
                <option value="">Selecione um colunista</option>
                {
                  columnists && columnists.map((columnist) => (
                    <option key={columnist.id} value={columnist.id}>{columnist.name}</option>
                  ))
                }
              </Select>
              <Input name='title' type='text' placeholder='Título' onChange={(e) => setSlug(slugGenerate(e.currentTarget.value))} required minLength={15} maxLength={120} />
              <Input name='subtitle' type='text' placeholder='Subtítulo' required minLength={15} maxLength={200} />
              <Input name='slug' type='text' placeholder='Slug' defaultValue={slug} required minLength={15} />
              <Editor
                textareaName='newsText'
                apiKey={process.env.REACT_APP_TINY_API_KEY}
                initialValue=""
                init={{
                  // height: 500,
                  menu: {
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                    view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                    insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                    format: { title: 'Format', items: 'bold italic underline fontsize | formats blockformats align lineheight | forecolor backcolor | removeformat' },
                    tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                    table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                    help: { title: 'Help', items: 'help' }
                  },
                  menubar: 'edit view insert format tools table',
                  plugins: [
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'fullscreen', 'insertdatetime', 'media', 'table', 'wordcount', 'emoticons', 'blockquote', 'emoticons', 'code'
                    // 'fullscreen', 'insertdatetime', 'media', 'table', 'wordcount', 'emoticons', 'blockquote', 'emoticons', 'quickbars', 'quickimage', 'code'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'blockquote alignleft aligncenter alignright alignjustify |' +
                    'bullist numlist checklist outdent indent | removeformat | table | link | image | media | emoticons',
                  media_live_embeds: true
                }}
                value={text}
                onEditorChange={(newValue, editor) => setText(newValue)}
              />

              <div className='imgContainer'>
                <span>Imagem de capa</span>
                <div className='imgContent'>
                  {
                    imgUrlReturn ?
                      <img src={imgUrlReturn} width={320} alt={imgDescriptionReturn} onClick={() => { handleOpenModal('media-selector') }} /> :
                      <img src={imgDefault} width={320} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector') }} />
                  }
                  <div className='inputsContent'>
                    <Input name='imgcover' type='text' placeholder='URL de imagem de capa' defaultValue={imgUrlReturn && imgUrlReturn} />
                    <Input name='imgcoverinfo' type='text' placeholder='Descrição da imagem de capa' defaultValue={imgDescriptionReturn && imgDescriptionReturn + ' (Foto: ' + imgCreditsReturn + ')'} />
                  </div>
                </div>
              </div>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
        {isModalVisible ? <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal> : null}
      </Container>
    </>
  )
}
