import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }

  :root {
    --blue-100: #f4f7fc;
    --gray-900: #121214;
    --gray-800: #202024;
    --gray-750: #29292E;
    --gray-400: #4E4E4E;
    --gray-200: #8F8F8F;
    --gray-100: #EAEAEA;
    --gray-50: #fafafa;
    --gray-1: #fefefe;
    --white: #FFFFFF;
  }

  /* @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  } */

  body {
    background: var(--blue-100);
    color: var(--gray-800);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, select, button {
    font: 400 1rem "Open Sans", sans-serif;
    outline: none;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  li {
    list-style: none;
  }
`;
