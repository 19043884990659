import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppProvider from './hooks';

import Routes from './routes';

import { GlobalStyle } from './styles/global';

function App() {
  useEffect(()=>{
    fetch('https://rconews-api.rco.com.br/ip').then((res)=>{
      return res.json();
    }).then((data)=>{
      if(data.toString() === '187.45.100.174'){
        window.location.href = 'https://rco.com.br';
      }
    })
  },[])
  return (
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </Router>
  );
}

export default App;
