import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';

import api from '../../../services/api';

import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import { useToast } from '../../../hooks/toast';

import imgDefault from '../../../assets/img-icon.jpeg';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ListContent
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  email: string;
  avatar: string;
  news: INewsProps[];
  group: IGroupProps;
}

interface IGroupProps {
  name: string;
}

interface INewsProps {
  created_at: Date;
}

export const PerJournalist = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<IUserProps[]>([]);
  const [filteredDate, setFilteredDate] = useState('');

  const newDate = new Date();
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
  const day = (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate())
  const toDay = year + '-' + month + '-' + day;

  useEffect(() => {
    setFilteredDate(toDay);
    try {
      api.get('/users', { timeout: 5000 }).then(response => {
        setUsers(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${err.message}`,
        });
        if (err.message === 'Request failed with status code 401') {
          history.push('/signout')
        }
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, history, toDay]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const busca = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/users?name=${busca}`, { timeout: 5000 });
        setUsers(response.data);
        console.log(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Relatório: Notícias por Jornalista' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input name='name' type="search" placeholder='Pesquisar pelo nome' onKeyPress={(e) => { handleSearch(e) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              <input type="date" defaultValue={toDay} onChange={(e)=>{setFilteredDate(e.currentTarget.value)}} />
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {users.filter(e => e.group.name === 'Jornalistas').length > 0 ?
                      <tr>
                        <th>

                        </th>
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      users && users.filter(e => e.group.name === 'Jornalistas' || e.name === 'Fabiano Rambo' || e.name === 'Ivan Carlos de Paula').map((user: IUserProps) =>
                        <tr key={user.id}>
                          <td>
                            <div className='leftContent'>
                              <img src={user.avatar ? user.avatar : imgDefault} alt={user.name} />
                              <span>{user.name}</span>
                            </div>
                          </td>
                          <td>
                            <div className='tagQuantidade'>{toDay === filteredDate ? 'Hoje' : filteredDate}</div>
                            {/* <div className='quantidade'>{user.news.map(n => n.created_at).filter(f => f.toString().substring(0, 10) === toDay).length}</div> */}
                            <div className='quantidade'>{user.news.map(n => n.created_at).filter(f => f.toString().substring(0, 10) === filteredDate).length}</div>
                          </td>
                          <td>
                            <div className='tagQuantidade'>Total</div>
                            <div className='quantidade'>{user.news.length}</div>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
