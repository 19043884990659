import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 1184px;
  min-height: 448px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;
  border-radius: 8px;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 648px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  outline: 1px solid #D1DDF1;
  height: 2rem;
  border-radius: 5px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #006EBF;
  }

  input {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0.25rem 0.5rem;
    width: 20rem;
    height: 2rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: background-color 0.2s;

      svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #006EBF;
    }

    &:hover {
      background-color: var(--gray-100);
    }

    &:active {
      background-color: var(--gray-50);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: scroll;

  .pageControl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;

   button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #006EBF;
    border-radius: 8px;
    outline: none;
    background: #D1DDF1;
    padding: 0.25rem;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }

    &:hover {
      background: #006EBF;
        color: var(--gray-50);
      }

      &:active {
        color: var(--gray-400);
      }
   }

    span {
      font-size: 1.25rem;
      font-weight: 700;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  table {
    border-collapse: collapse;
  }

  thead {
    text-align: left;
    vertical-align: center;
    border-bottom: 2px solid #D1DDF1;

    th {
      padding: 0.5rem 0;
    }
  }

  tbody {
    tr {
      /* height: 4rem; */
      border-bottom: 1px solid var(--gray-100);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s;

      &:hover {
        background: #ECEFF9;
      }
    }

    td {
        display: flex;
        padding: 0.5rem 0;

        img {
        object-fit: cover;
        width: 96px;
        height: 96px;
        border-radius: 3px;

        cursor: pointer;
      }

      .containerMediaInfo {
        display: flex;

        .descriptionContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 400px;
          border-right: 1px solid var(--gray-100);
          margin-left: 1rem;

          h4 {
            font-size: 1.125rem;
            font-weight: 700;
            color: #221C86;
          }

          span {
            margin-top: 0.5rem;
            font-size: 0.75rem;
            color: var(--gray-400);
          }
        }

        .copyURL {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 96px;
          width: 96px;
          cursor: pointer;
          border-radius: 50%;
          transition: all 0.2s;
          margin-left: 1rem;
          padding: 1rem;

          span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 0.75rem;
            color: var(--gray-400);
            text-align: center;
            word-wrap: break-word;
          }

          svg {
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            color: var(--gray-400);
          }

          &:hover {
            border: 1px dotted var(--gray-200);
          }

          &:active {
            border: 5px dotted var(--gray-200);
          }
        }

        .userContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-left: 1rem;
          padding-left: 1rem;
          border-left: 1px solid var(--gray-100);

          img {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            outline: 2px solid #f00;
            border: 1px solid #fff;
            object-fit: cover;
            vertical-align: middle;
          }

          span {
            font-size: 0.875rem;
            margin-left: 0.5rem;
          }
        }

        .createdContainer {
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: 1rem;
          padding-left: 1rem;
          border-left: 1px solid var(--gray-100);
          color: var(--gray-200);
          font-size: 0.75rem;

          span {
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
`;

