import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  ListContent
} from './styles';

interface ILiveVideoProps {
  id: string;
  type_source: string;
  active: boolean;
  url_source: string
}

export const LiveVideos = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [liveVideo, setLiveVideo] = useState<ILiveVideoProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/livevideos', { timeout: 5000 }).then(response => {
        setLiveVideo(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${err.message}`,
        });
        if (err.message === 'Request failed with status code 401') {
          history.push('/signout')
        }
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, history]);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Vídeos Ao Vivo' />
          <Body>
            <HeaderBody>
              {/* <ButtonCreate href='/livevideos/create'>
                <FiEdit />
                <span>Criar Vídeo Ao Vivo</span>
              </ButtonCreate> */}
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                liveVideo.map((lv)=>(
                  <Link key={lv.id} to={`/livevideos/edit/${lv?.id}`}>
                    <div className='videoContainer'>
                      <div className='videoImage'>
                        <iframe src={lv?.url_source} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                      <div className='videoUrl'>
                        {lv.type_source}
                      </div>
                    </div>
                  </Link>
                ))
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
