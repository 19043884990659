import { FiX } from 'react-icons/fi'

import {
  Container,
  Content,
  HeaderModal,
  BodyModal,
} from './styles';

let imgUrlReturn = '';
let imgDescriptionReturn = '';
let imgCreditsReturn = '';

const Modal = ({ title, id = 'modal', onClose, children }: any) => {
  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  }


  return (
    <Container id={id} onClick={handleOutsideClick}>
      <Content>
        <HeaderModal>
          <h2>{title}</h2>
          <button onClick={onClose}><FiX /></button>
        </HeaderModal>
        <BodyModal>
          <div>{children}</div>
        </BodyModal>
      </Content>
    </Container>
  );
}


export { Modal, imgUrlReturn, imgDescriptionReturn, imgCreditsReturn }
