import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiPlus, FiX, FiArrowLeft, FiLoader, FiTrash } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';


import Header from '../../components/Header';
import Menu from '../../components/Menu';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading,
  QuestionsContainer,
  ButtonFeature,
  QuestionsContent,
  QuestionItem
} from './styles';


interface IQuestionProps {
  id: string;
  survey_id: string;
  question: string;
}

interface ISurveyProps {
  id: string;
  title: string;
  description: string;
  active: boolean;
  public_view: boolean;
  expiration_date: Date;
}

export const SurveyEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [survey, setSurvey] = useState<ISurveyProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [divCreateQuestionActive, setDivCreateQuestionActive] = useState(false)
  const [questions, setQuestions] = useState<IQuestionProps[]>([])
  const [question, setQuestion] = useState('')
  const [active, setActive] = useState(false)
  const [publicView, setPublicView] = useState(false)

  useEffect(() => {
    setIsLoading(true);

    try {
      api.get(`/surveys/edit/${id}`, { timeout: 5000 }).then(response => {
        setSurvey(response.data);
        setActive(response.data.active)
        setPublicView(response.data.public_view)
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado.',
      });
    }
  }, [addToast, id]);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O Título é obrigatório!'),
        description: Yup.string(),
        active: Yup.boolean(),
        public_view: Yup.boolean(),
        expiration_date: Yup.date(),
      });

      let formData = {
        title: event.target['title'].value,
        description: event.target['description'].value,
        active: event.target['active'].checked,
        public_view: event.target['public_view'].checked,
        expiration_date: event.target['expiration_date'].value + 'T02:59:59.000Z',
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/surveys/edit/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A enquete foi salvo com sucesso.'
        });
        setIsLoading(false);
        history.push('/surveys',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Não foi possível salvar a enquete. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);

  const handleDeleteSurvey = useCallback(async () => {

    const answer = window.confirm(`Deseja realmente a Enquete`);

    if (answer) {
      setIsLoadingQuestions(true);

      try {
        await api.delete(`/surveys/${id}`, { timeout: 5000 });
        setIsLoadingQuestions(false);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A enquete foi excluída!'
        });
        history.push('/surveys')
      } catch (err) {
        setIsLoadingQuestions(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast, id, history]);

  const handlDeleteQuestion= useCallback(async (_id: string) => {

    const answer = window.confirm(`Deseja realmente a Questão?`);

    if (answer) {
      setIsLoadingQuestions(true);

      try {
        await api.delete(`/surveys/question/${_id}`, { timeout: 5000 });
        setIsLoadingQuestions(false);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A quesetão foi excluída!'
        });
        if(survey){
          api.get(`/surveys/questions/${survey?.id}`).then((response)=>{
            setQuestions(response.data)
          })
        }
      } catch (err) {
        setIsLoadingQuestions(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast, survey]);

  const handleCreateQuestion = useCallback(()=>{
    const createQuestionContainerDiv = document.getElementById('createQuestionContainer')
    const txtbuttoncreatequestionSpan = document.getElementById('txtbuttoncreatequestion')

    if(!divCreateQuestionActive){
      setDivCreateQuestionActive(true)
      if (createQuestionContainerDiv && txtbuttoncreatequestionSpan) {
        createQuestionContainerDiv.style.display = 'flex'
        txtbuttoncreatequestionSpan.innerText = 'Cancelar'
      }
    }else{
      setDivCreateQuestionActive(false)
      if (createQuestionContainerDiv && txtbuttoncreatequestionSpan) {
        createQuestionContainerDiv.style.display = 'none'
        txtbuttoncreatequestionSpan.innerText = 'Criar questão'
      }
    }

  },[divCreateQuestionActive])

  const handleSaveQuestion = useCallback(async ()=>{

    try {
      setIsLoadingQuestions(true)
      await api.post(`/surveys/question/${survey?.id}`, {question})
      const responseQuestions = await api.get(`/surveys/questions/${survey?.id}`)

      setQuestions(responseQuestions.data)
      handleCreateQuestion()
      setIsLoadingQuestions(false)
    }catch(err){
      setIsLoadingQuestions(false)
    }

  },[survey, handleCreateQuestion, question])

  const handleSaveChangeQuestion = useCallback(async (e, _id: string, question: string)=>{

    if (e.key === 'Enter') {
      try {
        await api.put(`/surveys/question/edit/${_id}`, {question})
        addToast({
          type: 'success',
          title: 'Salvado',
          description: 'Questão alterada',
        });
      }catch(err){
        addToast({
          type: 'error',
          title: 'Erro',
          description: '' + err,
        });
      }
    }
  },[addToast]);

  useEffect(()=>{
    if(survey){
      api.get(`/surveys/questions/${survey?.id}`).then((response)=>{
        setQuestions(response.data)
      })
    }

  },[survey])

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar Enquete' />
          <Body>
            <HeaderBody>
              <Link to="/surveys"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            {
              isLoading && <h3>Carregando...</h3>
            }
            <FormContainer onSubmit={handleSubmit}>
              <Input name='title' defaultValue={survey?.title} type='text' placeholder='Título' />
              <Input name='description' defaultValue={survey?.description} type='text' placeholder='Descrição' />
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', height: '2rem'}}>
                <span style={{width: '12rem'}}>Ativo:</span>
                <input name='active' type='checkbox' placeholder='Ativo' checked={active} onChange={()=>{setActive(!active)}} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', height: '2rem'}}>
              <span style={{width: '12rem'}}>Visualização pública:</span>
                <input name='public_view' type='checkbox' placeholder='Visualização pública' checked={publicView} onChange={()=>{setPublicView(!publicView)}}  />
              </div>
              <Input name='expiration_date' type='date' placeholder='Data de expiração' defaultValue={survey?.expiration_date.toString().substring(0,10)} />
              <Footer>
                <span onClick={handleDeleteSurvey}>Excluir</span>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
            <QuestionsContainer id='questionscontainer'>
              <div className='headerQuestions'>
                <span>Questões</span>
                <div className='createQuestionContainer'>
                  <ButtonFeature onClick={handleCreateQuestion}>{divCreateQuestionActive ? <FiX /> : <FiPlus />}<span id='txtbuttoncreatequestion'>Criar questão</span></ButtonFeature>
                  <div className='createQuestionInput' id='createQuestionContainer'>
                    <input type="text" placeholder='Escreva a questão' onChange={(e)=>{setQuestion(e.target.value)}} />
                    <button type='button' onClick={handleSaveQuestion}><FiCheck />Salvar</button>
                  </div>
                </div>
              </div>
              <QuestionsContent id='questionscontent'>
                {
                  isLoadingQuestions ? <span>Carregando...</span> : questions.length > 0 && questions.map(q => (
                    <QuestionItem key={q.id}>
                      <input
                        type="text"
                        defaultValue={q.question} placeholder='Escreva uma questão'
                        readOnly onDoubleClick={(e)=>{e.currentTarget.readOnly = false}}
                        onKeyPress={(e)=>{handleSaveChangeQuestion(e, q.id, e.currentTarget.value)}}
                      />
                      <button id={`${q.id}`} type='button' onClick={(e)=>{handlDeleteQuestion(e.currentTarget.id)}}><FiTrash /></button>
                    </QuestionItem>
                  ))
                }
              </QuestionsContent>
            </QuestionsContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
