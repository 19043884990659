import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { format, addHours } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FiCheck, FiArrowLeft, FiLoader, FiPlus } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import imgDefault from '../../assets/img-icon.jpeg';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Modal } from '../../components/Modal';
import { MediaCenterModalContent, imgUrlReturn } from '../../components/MediaCenterModalContent';
import { ProgramScheduleCreate } from '../../components/ProgramScheduleCreate'


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading,
  ButtonFeature
} from './styles';

interface INewsFormData {
  id: string;
  name: string;
  about: string;
  imgcover: string;
  Radio_Schedules: ISchedulesProps[];
}

interface ISchedulesProps {
  id: string;
  active: boolean;
  week_day: number;
  time_start: Date;
  time_end: Date;
}

const weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

export const ProgramEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [programs, setPrograms] = useState<INewsFormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('');
  const [modalOption, setModalOption] = useState(false);
  const [imgCover, setImgCover] = useState('');

  useEffect(() => {
    setIsLoading(true);

    try {
      api.get(`/radioprograms/${id}`, { timeout: 5000 }).then(response => {
        setPrograms(response.data);
        setImgCover(response.data.imgcover);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, id]);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome é obrigatório!'),
        about: Yup.string().required('Sobre é obrigatório!'),
        imgcover: Yup.string(),
      });

      let formData = {
        name: event.target['name'].value,
        about: event.target['about'].value,
        imgcover: event.target['imgcover'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/radioprograms/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'O programa foi salvo com sucesso.'
        });
        setIsLoading(false);
        history.push('/programs',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Não foi possível salvar o programa. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);

  const handleOpenModal = useCallback((_modalId: string, modalopt: boolean) => {
    setModalId(_modalId);
    setModalOption(modalopt);
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    if (imgUrlReturn) {
      setImgCover(imgUrlReturn);
    }
    setIsModalVisible(false);
  }, []);

  const deleteSchedule = useCallback(async (id: string) => {

    const answer = window.confirm(`Deseja realmente Excluir a Programação?`);

    if (answer) {
      setIsLoading(true);

      try {
        await api.delete(`/radioschedules/${id}`, { timeout: 5000 });
        setIsLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A programação foi excluída!'
        });
        document.location.reload();
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar Programa' />
          <Body>
            <HeaderBody>
              <Link to="/programs"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            {
              isLoading && <h3>Carregando...</h3>
            }
            <FormContainer onSubmit={handleSubmit}>
              <Input name='name' defaultValue={programs?.name} type='text' placeholder='Nome' />
              <Input name='about' defaultValue={programs?.about} type='text' placeholder='Sobre' />
              <div className='scheduleContainer'>
                <div className='scheduleHeader'>
                  <span>Programação</span>
                  <ButtonFeature type='button' onClick={() => { handleOpenModal('media-selector', true) }}>
                    <FiPlus /> Criar programação
                  </ButtonFeature>
                </div>
                <div className='scheduleBody'>
                  <ul>
                    {
                      programs && programs.Radio_Schedules.map((schedule: ISchedulesProps) => (
                        <li key={schedule.id} className='scheduleItemContainer' onDoubleClick={() => { deleteSchedule(schedule.id) }}>
                          <div className='headerItem'>{weekDays[schedule.week_day]}</div>
                          <div className='timeStart'>
                            {format(new Date(addHours(new Date(schedule.time_start), 3)), 'HH:mm:ss', { locale: ptBR })}
                          </div>
                          <div className='timeEnd'>
                            {format(new Date(addHours(new Date(schedule.time_end), 3)), 'HH:mm:ss', { locale: ptBR })}
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className='imgContainer'>
                <span>Imagem de capa</span>
                <div className='imgContent'>
                  {
                    imgCover ?
                      <img src={imgCover} width={320} alt={programs?.name} onClick={() => { handleOpenModal('media-selector', false) }} /> :
                      <img src={imgDefault} width={320} alt='Imagem padrão' onClick={() => { handleOpenModal('media-selector', false) }} />
                  }
                  <div className='inputsContent'>
                    <label htmlFor="imgcover">URL da Imagem</label>
                    <Input name='imgcover' defaultValue={imgCover} type='text' placeholder='URL da Imagem' />
                  </div>
                </div>
              </div>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
        {isModalVisible ?
          modalOption ?
            <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><ProgramScheduleCreate programId={programs?.id} /></Modal> :
            <Modal id={modalId} title='Selecionar mídia' onClose={() => handleCloseModal(modalId)}><MediaCenterModalContent onClose={() => handleCloseModal(modalId)} /></Modal>
          : null
        }
      </Container>
    </>
  )
}
