import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  color: var(--gray-800);
  border-radius: 5px;
  font-size: 1rem;

  .user-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      color: var(--gray-200);
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--gray-1);
    min-width: 150px;
    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.1);
    border: 1px solid var(--gray-100);
    z-index: 1;
    border-radius: 5px;

    a {
      display: flex;
      padding: 1rem;

      &:hover {
        background: var(--gray-100);
      }
    }

    .line-separator {
      border-top: 1px solid var(--gray-100);
    }
  }

  &:hover .dropdown-content {
    display: block;
  }

  &:active .dropdown-content {
    display: block;
  }
`;
