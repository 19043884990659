import { useCallback, useState } from 'react';
import { FiCheck, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Body,
  FormContainer,
  Select,
  Input,
  Footer,
  ButtonSave,
  Loading
} from './styles';

export const ProgramScheduleCreate = ({ programId }: any) => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      let formData = {
        week_day: Number(event.target["week_day"].value),
        time_start: '1970-01-01T' + event.target["time_start"].value + ':00.000Z',
        time_end: '1970-01-01T' + event.target["time_end"].value + ':00.000Z',
        radio_program_id: programId
      };

      setIsLoading(true);

      await api.post('/radioschedules', formData, { headers: api.defaults.headers });
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'A programação foi criada com sucesso.'
      });
      setIsLoading(false);
      document.location.reload();
    } catch (err) {
      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro: ' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [addToast, programId]);


  return (
    <>
      <Container>
        <Content>
          <Body>
            <FormContainer onSubmit={handleSubmit}>
              <label>Dia da semana</label>
              <Select name="week_day">
                <option value="">Selecione um dia</option>
                <option value="0">Domingo</option>
                <option value="1">Segunda</option>
                <option value="2">Terça</option>
                <option value="3">Quarta</option>
                <option value="4">Quinta</option>
                <option value="5">Sexta</option>
                <option value="6">Sábado</option>
              </Select>
              <label>Hora de início</label>
              <Input name="time_start" type='time' autoComplete='off' autoCorrect='off' />
              <label>Hora de fim</label>
              <Input name="time_end" type='time' autoComplete='off' autoCorrect='off' />
              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
