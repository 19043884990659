import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiArrowLeft, FiImage, FiMusic, FiVideo } from 'react-icons/fi';

// import { useToast } from '../../hooks/toast';

// import imgDefault from '../../assets/img-icon.jpeg';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { ModalMediaCreateImage } from '../../components/ModalMediaCreateImage';
import { ModalMediaCreateAudio } from '../../components/ModalMediaCreateAudio';
import { ModalMediaCreateVideo } from '../../components/ModalMediaCreateVideo';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  BodyContent,
} from './styles';

export const MediaSelectType = () => {
  const {push} = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('');
  const [modal, setModal] = useState({})

  const handleOpenModal = useCallback((_modalId: string, _modal: {}) => {
    setModalId(_modalId);
    setModal(_modal)
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback((_modalId: string) => {
    setModalId(_modalId);
    setIsModalVisible(false);
  }, []);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar Mídia' />
          <Body>
            <HeaderBody>
              <Link to="/mediacenter"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <BodyContent>
              {/* <div className='item' onClick={()=>{push('/media/create/image')}}> */}
              <div className='item' onClick={() => { handleOpenModal('upload-image', <ModalMediaCreateImage id='upload-image' onClose={() => handleCloseModal('upload-image')} />) }}>
                <FiImage />
                <span>Imagem</span>
              </div>
              <div className='item' onClick={() => { handleOpenModal('upload-audio', <ModalMediaCreateAudio id='upload-audio' onClose={() => handleCloseModal('upload-audio')} />) }}>
                <FiMusic />
                <span>Áudio</span>
              </div>
              <div className='item' onClick={() => { handleOpenModal('upload-video', <ModalMediaCreateVideo id='upload-video' onClose={() => handleCloseModal('upload-video')} />) }}>
                <FiVideo />
                <span>Vídeo</span>
              </div>
            </BodyContent>
          </Body>
        </Content>
        {isModalVisible ? modal : null}
      </Container>
    </>
  )
}
