import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  transform: color 0.2s;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--gray-200);
    }
  }
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  padding: 2rem;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 8px;
    border: 1px solid #006EBF;
    width: 182px;
    height: 182px;
    font-size: 1.25rem;
    transition: all 0.3s;
  }

  .item svg {
    font-size: 2.5rem;
    color: #006EBF;
  }

  .item:hover {
    background: #006EBF;
    cursor: pointer;
    color: #fff;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.5);

    svg {
      color: #fff;
    }
  }

  .item:active {
    background: #102E6D;
  }
`;
