import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiPlus, FiX, FiArrowLeft, FiLoader } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../components/Header';
import Menu from '../../components/Menu';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading,
  QuestionsContainer,
  ButtonFeature,
  QuestionsContent,
  QuestionItem
} from './styles';

interface ISurveyProps {
  id: string;
  title: string;
  description: string;
  active: boolean;
  public_view: boolean;
  expiration_date: Date;
}

interface IQuestionsProps {
  id: string;
  survey_id: string;
  question: string;
}

export const SurveyCreate = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [divCreateQuestionActive, setDivCreateQuestionActive] = useState(false)
  const [survey, setSurvey] = useState<ISurveyProps>()
  const [questions, setQuestions] = useState<IQuestionsProps[]>([])
  const [question, setQuestion] = useState('')

  const handleSubmitSurvey = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O Título é obrigatório!'),
        description: Yup.string(),
        active: Yup.boolean(),
        public_view: Yup.boolean(),
        expiration_date: Yup.date(),
      });

      let formData = {
        title: event.target['title'].value,
        description: event.target['description'].value,
        active: event.target['active'].checked,
        public_view: event.target['public_view'].checked,
        expiration_date: event.target['expiration_date'].value + 'T02:59:59.000Z',
      };

      console.log(formData)

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        const response = await api.post('/surveys', formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Enquete criada',
          description: 'A enquete foi criada com sucesso.'
        });
        setIsLoading(false);
        setSurvey(response.data)
        if(response.data) {

          const divQuestionsContainer = document.getElementById('questionscontainer')
          if(divQuestionsContainer) {
            divQuestionsContainer.style.display = 'flex'
          }

          const footerDiv = document.getElementById('footer')
          if(footerDiv) {
            footerDiv.style.display = 'none'
          }
        }
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar programa',
        description: 'Não foi possível criar o programa. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  const handleCreateQuestion = useCallback(()=>{
    const createQuestionContainerDiv = document.getElementById('createQuestionContainer')
    const txtbuttoncreatequestionSpan = document.getElementById('txtbuttoncreatequestion')

    if(!divCreateQuestionActive){
      setDivCreateQuestionActive(true)
      if (createQuestionContainerDiv && txtbuttoncreatequestionSpan) {
        createQuestionContainerDiv.style.display = 'flex'
        txtbuttoncreatequestionSpan.innerText = 'Cancelar'
      }
    }else{
      setDivCreateQuestionActive(false)
      if (createQuestionContainerDiv && txtbuttoncreatequestionSpan) {
        createQuestionContainerDiv.style.display = 'none'
        txtbuttoncreatequestionSpan.innerText = 'Criar questão'
      }
    }

  },[divCreateQuestionActive])

  const handleSaveQuestion = useCallback(async ()=>{
    await api.post(`/surveys/question/${survey?.id}`, {question})

    const responseQuestions = await api.get(`/surveys/questions/${survey?.id}`)
    setQuestions(responseQuestions.data)
    handleCreateQuestion()
  },[survey, handleCreateQuestion, question])

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar Enquete' />
          <Body>
            <HeaderBody>
              <Link to="/surveys"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmitSurvey}>
              <Input name='title' type='text' placeholder='Título' />
              <Input name='description' type='text' placeholder='Descrição' />
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', height: '2rem'}}>
                <span style={{width: '12rem'}}>Ativo:</span>
                <input name='active' type='checkbox' placeholder='Ativo' />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', height: '2rem'}}>
              <span style={{width: '12rem'}}>Visualização pública:</span>
                <input name='public_view' type='checkbox' placeholder='Visualização pública' />
              </div>
              <Input name='expiration_date' type='date' placeholder='Data de expiração' />

              <Footer id='footer'>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><span>Salvar</span><FiCheck /></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
            <QuestionsContainer id='questionscontainer'>
              <div className='headerQuestions'>
                <span>Questões</span>
                <div className='createQuestionContainer'>
                  <ButtonFeature onClick={handleCreateQuestion}>{divCreateQuestionActive ? <FiX /> : <FiPlus />}<span id='txtbuttoncreatequestion'>Criar questão</span></ButtonFeature>
                  <div className='createQuestionInput' id='createQuestionContainer'>
                    <input type="text" placeholder='Escreva a questão' onChange={(e)=>{setQuestion(e.target.value)}} />
                    <button type='button' onClick={handleSaveQuestion}><FiCheck />Salvar</button>
                  </div>
                </div>
              </div>
              <QuestionsContent id='questionscontent'>
                {
                  questions.length > 0 && questions.map(q => (
                    <QuestionItem key={q.id}>
                      {q.question}
                    </QuestionItem>
                  ))
                }
              </QuestionsContent>
            </QuestionsContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
