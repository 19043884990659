import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../components/Header';
import Menu from '../../components/Menu';


import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading,
} from './styles';

interface ILiveVideoProps {
  id: string;
  type_source: string;
  url_source: string
  active: boolean;
}


export const LiveVideosEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [liveVideo, setLiveVideo] = useState<ILiveVideoProps>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      api.get(`/livevideos/${id}`, { timeout: 5000 }).then(response => {
        setLiveVideo(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, id]);


  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        url_source: Yup.string().required('A URL é obrigatória!'),
        type_source: Yup.string().required('Tipo é Requerido!'),
        active: Yup.boolean(),
      });

      let formData = {
        type_source: event.target['type_source'].value,
        url_source: event.target['url_source'].value,
        active: event.target['active'].checked,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/livevideos/${id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A URL foi salva com sucesso.'
        });
        setIsLoading(false);
        history.push('/livevideos',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Não foi possível salvar a URL. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, id]);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editar URL do Vídeo' />
          <Body>
            <HeaderBody>
              <Link to="/livevideos"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            {
              isLoading && <h3>Carregando...</h3>
            }
            <FormContainer onSubmit={handleSubmit}>
              <Input name='type_source' defaultValue={liveVideo?.type_source} type='text' placeholder='Tipo' readOnly />
              <Input name='url_source' defaultValue={liveVideo?.url_source} type='text' placeholder='URL' />
              <Input name='active' type='checkbox' defaultChecked={liveVideo?.active} />

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
