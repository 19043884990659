// import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useAuth } from '../../hooks/auth';

// import { useAuth } from '../../hooks/auth';
// import api from '../../services/api';

import {
  Container,
  Content,
  Body,
  ItemContainer,
} from './styles';

// interface IUserProps {
//   id: string;
//   name: string;
//   email: string;
//   active: string;
//   avatar: string;
//   group: IGroupProps;
// }

// interface IGroupProps {
//   id: string;
//   name: string;
// }

export const Settings = () => {
  const { user } = useAuth();

  function showResourceAdministrator(group: string) {
    if (group === '70417110-bedb-4d17-8891-56b254a1d358') {
      return true;
    } else {
      return false;
    }
  }

  function showResourceManager(group: string) {
    if (group === 'b59d94bb-53da-440d-8bf4-025a30d3b990' || group === '70417110-bedb-4d17-8891-56b254a1d358') {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Configurações' />
          <Body>

            {
              showResourceAdministrator(user.group_id) &&
              <ItemContainer>
                <Link to='/editorships'>Editorias</Link>
              </ItemContainer>
            }

            {
              showResourceAdministrator(user.group_id) &&
              <ItemContainer>
                <Link to='/users'>Usuários</Link>
              </ItemContainer>
            }

            {
              showResourceManager(user.group_id) &&
              <ItemContainer>
                <Link to='/programs'>Programas</Link>
              </ItemContainer>
            }

            <ItemContainer>
              <Link to='/livevideos'>Vídeos Home Page</Link>
            </ItemContainer>

            {
              showResourceAdministrator(user.group_id) &&
              <ItemContainer>
                <Link to='/topfive'>Top 5</Link>
              </ItemContainer>
            }

            {
              showResourceManager(user.group_id) &&
              <ItemContainer>
                <Link to='/promotions'>Promoções</Link>
              </ItemContainer>
            }

          </Body>
        </Content>
      </Container>
    </>
  )
}
