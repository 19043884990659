// import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Menu from '../../components/Menu';

import {
  Container,
  Content,
  Body,
  ItemContainer,
} from './styles';

export const Reports = () => {
  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Relatórios' />
          <Body>

            <ItemContainer>
              <Link to='/reports/news/per-journalist'>Notícias por Jornalista</Link>
            </ItemContainer>

            <ItemContainer>
              <Link to='/reports/logs'>Logs</Link>
            </ItemContainer>

          </Body>
        </Content>
      </Container>
    </>
  )
}
