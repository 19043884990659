import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface IColumnistProps {
  id: string;
  name: string;
  about: string;
  imgcover: string;
  linked_user_id: string;
  avatar: string;
  slug: string;
  active: boolean;
  Publications: IPublicationProps[];
}

interface IPublicationProps {
  id: string;
  columnist_id: string;
  columnists: IColumnistProps;
  title: string;
  subtitle: string;
  slug: string;
  imgcover: string;
  imgcoverinfo: string;
  text: string;
}

export const ColumnistDetails = () => {
  const { slug }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [columnist, setColumnist] = useState<IColumnistProps>();
  const [publications, setPublications] = useState<IPublicationProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get(`/columnists/admin/${slug}`, { timeout: 5000 }).then((response) => {
        setColumnist(response.data);
        setPublications(response.data.Publications);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, slug, columnist?.id]);

  const handleSearch = useCallback(async (e: any) => {
    e.preventDefault();

    const busca = e.target['title'].value;

    setIsLoading(true);

    try {
      api.get(`/publications/admin?title=${busca}`, { timeout: 5000 }).then((response) => {
        setPublications(response.data);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast]);

  const deletePublication = useCallback(async (id: string) => {

    const answer = window.confirm('Deseja realmente Excluir a Publicação?');

    if (answer) {
      setIsLoading(true);

      try {
        await api.delete(`/publications/${id}`, { timeout: 5000 });
        setIsLoading(false);
        document.location.reload();
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);


  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle={`Publicações de ${isLoading ? 'Carregando...' : columnist?.name}`} />
          <Body>
            <HeaderBody>
              <SearchContent onSubmit={handleSearch}>
                <input type="search" name='title' placeholder='Pesquisar pelo título' />
                <button type='submit'><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/columnists/publications/create'>
                <FiEdit />
                <span>Criar publicação</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    <tr>
                      <th>Título</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      publications && publications.map((publication) =>
                        <tr key={publication.id} onDoubleClick={() => { deletePublication(publication.id) }}>
                          <td>
                            <img src={publication.imgcover} alt="" />
                            <span><Link to={`/columnists/publications/edit/${publication.id}`}>{publication.title}</Link></span>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
