import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface IColumnistsProps {
  id: string;
  name: string;
  about: string;
  imgcover: string;
  linked_user_id: string;
  avatar: string;
  slug: string;
  active: boolean;
}

export const Columnists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columnists, setColumnists] = useState<IColumnistsProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/columnists/admin', { timeout: 5000 }).then(response => {
        setColumnists(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast]);

  const handleSearch = useCallback(async (e: any) => {
    e.preventDefault();

    const busca = e.target['name'].value;

    setIsLoading(true);

    try {
      const response = await api.get(`/columnists/admin?name=${busca}`, { timeout: 5000 });
      setColumnists(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: '' + err,
      });
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Colunistas' />
          <Body>
            <HeaderBody>
              <SearchContent onSubmit={handleSearch}>
                <input type="search" name='name' placeholder='Pesquisar pelo nome' autoComplete='off' autoFocus />
                <button type='submit'><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/columnists/publications/create'>
                <FiEdit />
                <span>Criar publicação</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {columnists.length > 0 ?
                      <tr>
                        {/* <th>Nome</th> */}
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      columnists && columnists.map((columnistsItem: IColumnistsProps) =>
                        <tr key={columnistsItem.id}>
                          <td>
                            <img src={columnistsItem.avatar} alt={columnistsItem.name} />
                            <span><Link to={`/columnists/details/${columnistsItem.slug}`}>{columnistsItem.name}</Link></span>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
