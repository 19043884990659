import { useCallback, useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import { Modal } from '../../../components/Modal';
import { EditorshipCreate } from '../../../components/EditorshipCreate';
import { EditorshipEdit } from '../../../components/EditorshipEdit';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { AiOutlineSearch } from 'react-icons/ai';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ItemBody
} from './styles';

interface IEditorshipProps {
  id: string;
  name: string;
  url: string;
  position: number;
}

export const EditorShips = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [editorShipsList, setEditorShipsList] = useState<IEditorshipProps[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [titleModal, setTitleModal] = useState('');
  const [oldPosition, setOldPosition] = useState<number>();
  const [newPosition, setNewPosition] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    api.get('/editorships/admin').then(response => {
      setEditorShipsList(response.data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
    });
  }, []);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const busca = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/editorships/admin?name=${busca}`, { timeout: 5000 });
        setEditorShipsList(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const handleOpenModal = useCallback((modalId: string, url: string) => {
    if (modalId === 'create') {
      setTitleModal('Criar editoria')
      setModalObject(<EditorshipCreate />)
    }
    else if (modalId === 'edit') {
      setTitleModal('Editar editoria');
      setModalObject(<EditorshipEdit>{url}</EditorshipEdit>);
    }

    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
    document.location.reload();
  }, []);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Editorias' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input type="search" placeholder='Pesquisar pela descrição' onKeyPress={(e) => { handleSearch(e) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              {
                user.id === '5c6312c7-2d90-4479-80a6-8b7d63e952b5' && <ButtonCreate onClick={() => handleOpenModal('create', '')}>Criar editoria</ButtonCreate>
              }
            </HeaderBody>
            <ItemBody>
              {
                isLoading ? <h3>Carregando...</h3> :
                  editorShipsList && editorShipsList.sort().map((editorship: IEditorshipProps, index) => (
                    <button
                      key={index}
                      onClick={() => { handleOpenModal('edit', editorship.url) }}
                    >
                      <strong>{editorship.position}</strong> <span>{editorship.name}</span>
                    </button>
                  ))
              }
            </ItemBody>
          </Body>
        </Content>
        {isModalVisible ? <Modal title={titleModal} onClose={() => handleCloseModal()}>{modalObject}</Modal> : null}
      </Container>
    </>
  )
}
