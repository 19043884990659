import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../components/Header';
import Menu from '../../components/Menu';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading,
} from './styles';

// interface IUserProps {
//   id: string;
//   name: string;
//   email: string;
//   avatar: string;
//   group: IGroupProps;
//   active: boolean;
// }

// interface IGroupProps {
//   id: string;
//   name: boolean;
// }

export const PasswordChange = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [msgConfirm, setMsgConfirm] = useState('');
  const [isValid, setIsValid] = useState(false);


  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      let formData = {
        password: event.target['password'].value,
        confirmpassword: event.target['confirmpassword'].value,
      };

      if (formData.password === '' || formData.confirmpassword === '') {
        if (formData.password !== formData.confirmpassword) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Senhas não conferem.'
          });
        } else {
          setIsValid(true);
        }
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Digite alguma coisa'
        });
      } else {
        setIsValid(true);
      }

      setIsLoading(true);

      if (isValid) {
        await api.patch(`/users/${user.id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Senha alterada com sucesso.'
        });
        setIsLoading(false);
        history.push('/signout',);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Não foi possível aterar a senha.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, user.id, isValid]);

  const verifyPassword = useCallback((confirmpassword: string) => {
    if (password !== confirmpassword) {
      setMsgConfirm('As senhas não conferem!');
    } else {
      setMsgConfirm('');
    }
  }, [password]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Alterar senha' />
          <Body>
            <HeaderBody>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <span className='userName'>{user.name}</span>
              <Input name='password' type='password' placeholder='Nova senha' onChange={(e) => { setPassword(e.target.value) }} />
              <Input name='confirmpassword' type='password' placeholder='Confirmar nova senha' onChange={(e) => { verifyPassword(e.target.value) }} />
              <span>{msgConfirm}</span>

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
