import { useCallback, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import api from '../../../services/api';

import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  ListContent
} from './styles';

interface ILogsProps {
  id: string;
  remote_ip: string;
  user_agent: string;
  created_at: Date;
}

export const LogsLgpd = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState<ILogsProps[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    try {
      api.get(`/termsofuse/consent/admin/list?page=${page}`, { timeout: 10000 }).then(response => {
        setLogs(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, page]);

  const handleBackPage = useCallback(() => {
    if (page >= 2) {
      setIsLoading(true);
      setPage(page - 1);
    }
  }, [page]);

  const handleForwardPage = useCallback(() => {
    if (logs.length === 20) {
      setIsLoading(true);
      setPage(page + 1);
    }
  }, [page, logs]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Relatório: Logs LGPD' />
          <Body>
            <HeaderBody>
            </HeaderBody>
            <ListContent>
              <div className='pageControl'>
                <button onClick={handleBackPage} style={{ cursor: 'pointer' }}><FaAngleLeft /></button>
                <span>{page}</span>
                <button onClick={handleForwardPage} style={{ cursor: 'pointer' }}><FaAngleRight /></button>
              </div>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    <tr>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      logs && logs.map((logItem: ILogsProps) =>
                        <tr key={logItem.id}>
                          <td>
                            <div className='containerLogsInfo'>
                              <div className='userContainer'>
                                <span>{logItem.user_agent}</span>
                              </div>
                              <div className='createdContainer'>
                                <span>{new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(logItem.created_at))}</span>
                              </div>
                              <div className='ipContainer'>
                                <span>{logItem.remote_ip}</span>
                              </div>
                            </div>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
