import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 648px;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 648px;

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.675rem;
    }

    input {
      min-width: 27.5rem;
      padding: 0.5rem;
      border-radius: 5px;
      outline: none;
      border: 1px solid #9E94FB;
      margin-bottom: 1rem;
    }

    input[type="file"] {
      display: none;
    }

    .labelFile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 12rem;
      border: 1px solid #9E94FB;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: var(--gray-100);
        border: 4px solid #9E94FB;
      }

      &:active {
        border: 1px solid #9E94FB;
        background: var(--gray-1);
      }
    }


    img {
      width: 648px;
      height: 486px;
    }
  }
`;


