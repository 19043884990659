import styled from "styled-components";

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-750);
  border-radius: 0.625rem;
  border: 1px solid var(--gray-200);
  width: 100%;
  padding: 0.875rem;
  margin-top: 0.5rem;
  color: var(--gray-100);
  font-size: 0.875rem;

  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(1.5);
  }

  &:active {
    filter: brightness(1);
  }
`;
