import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  border-radius: 8px;
  height: 400px;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 648px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  outline: 1px solid #D1DDF1;
  height: 2rem;
  border-radius: 5px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #006EBF;
  }

  input {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0.25rem 0.5rem;
    width: 20rem;
    height: 2rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: background-color 0.2s;

      svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #006EBF;
    }

    &:hover {
      background-color: var(--gray-100);
    }

    &:active {
      background-color: var(--gray-50);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const ButtonCreate = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: #006EBF;
  border-radius: 5px;
  color: #fff;
  padding: 0.75rem 1rem;
  transition: background-color 0.2s;
  cursor: pointer;

  @media (max-width: 648px) {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  svg {
    margin-right: 0.75rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }
`;

export const ItemBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #D1DDF1;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;

  button {
      display: flex;
      align-items: center;
      padding: 1rem;
      /* width: 100%; */
      min-height: 3rem;
      border: 1px solid #006EBF;
      border-radius: 5px;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      transition: background-color 0.2s;
      background-color: #fff;
      gap: 0.5rem;
      transition: 0.2s;

      strong {
        border: 1px solid #006EBF;
        border-radius: 50%;
        width: 2rem;
        min-width: 2rem;
        max-width: 2rem;
        heigth: 2rem;
        min-heigth: 2rem;
        max-heigth: 2rem;
        padding: 0.25rem;
      }

      &:hover {
        outline: 1px solid #006EBF;
        border-radius: 5px;
        box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
      }

      &:active {
        background-color: #006EBF;
        color: #fff;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
      }
    }
`;
