import styled, { css } from "styled-components";

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const InputContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background: var(--gray-750);
  border-radius: 0.625rem;
  width: 100%;
  padding: 0.875rem;

  border: 1px solid var(--gray-200);
  color: var(--gray-200);

  & + div {
    margin-top: 0.5rem;
  }

  ${props => props.isErrored && css`
    border-color: #c53030;
  `}

  ${props => props.isFocused && css`
    color: var(--gray-100);
    border-color: var(--gray-100);
  `}

  ${props => props.isFilled && css`
    color: var(--gray-100);
  `}

  input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    color: var(--gray-100);
    font-size: 0.875rem;

    ::placeholder {
      color: var(--gray-200);
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

export const InputError = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
`;
