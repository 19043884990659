import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiEdit, FiTrash } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';

import imgDefault from '../../assets/img-icon.jpeg';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface IProgramProps {
  id: string;
  name: string;
  about: string;
  imgcover: string;
}

export const Programs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<IProgramProps[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      api.get('/radioprograms', { timeout: 5000 }).then(response => {
        setPrograms(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
      }).finally(() => {
        setIsLoading(false);
      });

    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const busca = e.target.value;

      setIsLoading(true);

      try {
        const response = await api.get(`/radioprograms?name=${busca}`, { timeout: 5000 });
        setPrograms(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const deleteProgram = useCallback(async (id: string, name: string) => {

    const answer = window.confirm(`Deseja realmente Excluir o programa ${name}?`);

    if (answer) {
      setIsLoading(true);

      try {
        await api.delete(`/radioprograms/${id}`, { timeout: 5000 });
        setIsLoading(false);
        document.location.reload();
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Programas' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input name='name' type="search" placeholder='Pesquisar pelo nome' onKeyPress={(e) => { handleSearch(e) }} />
                <button onClick={(handleSearch)}><AiOutlineSearch /></button>
              </SearchContent>
              <ButtonCreate href='/programs/create'>
                <FiEdit />
                <span>Criar Programa</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {programs.length > 0 ?
                      <tr>
                        <th>Nome do programa</th>
                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      programs && programs.map((program: IProgramProps) =>
                        <tr key={program.id}>
                          <td>
                            <img src={program.imgcover ? program.imgcover : imgDefault} alt={program.name} />
                            <span><Link to={`/programs/edit/${program.id}`}>{program.name}</Link></span>
                          </td>
                          <td>
                            <button className='deleteButton' onClick={() => { deleteProgram(program.id, program.name) }}><FiTrash /></button>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
      </Container>
    </>
  )
}
