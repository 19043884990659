import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  background: #00204d;
  width: 4rem;

  /* &:hover {
    width: 16rem;
  } */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 4rem;
  height: 100%;

  /* &:hover {
    width: 16rem;
    span {
      display: flex;
      margin-left: 1rem;
    }
  } */
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  width: 100%;
  color: var(--gray-200);
  padding: 0.25rem;
  padding-left: 1rem;
  gap: 1rem;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      color: #fff;
    }
  }

  svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    span {
      display: none;
    }

  &:hover {
    background: #102E6D;
  }
`;
