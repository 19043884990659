import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  Content,
  Body,
  FormContainer,
  Input,
  Footer,
  ButtonSave,
  Loading
} from './styles';

interface IEditorshipProps {
  id: string;
  name: string;
  url: string;
  position: number;
}

export const EditorshipEdit = ({ children }: any) => {
  const history = useHistory();
  const { user } = useAuth();
  user.id !== '5c6312c7-2d90-4479-80a6-8b7d63e952b5' && history.push('/settings')
  const { url }: any = useParams();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [editorship, setEditorship] = useState<IEditorshipProps>()

  useEffect(() => {
    setIsLoading(true);
    api.get(`/editorships/admin/${children}`).then(response => {
      setEditorship(response.data[0]);
      setIsLoading(false);
    })
  }, [url, children]);


  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome é obrigatório!'),
        url: Yup.string().required('A URL é obrigatória'),
        position: Yup.string().required('A Posição é obrigatória'),
      });

      let formData = {
        name: event.target[0].value,
        url: event.target[1].value,
        position: event.target[2].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.put(`/editorships/${editorship?.id}`, formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Editoria Salva',
          description: 'A editoria foi salva com sucesso.'
        });
        setIsLoading(false);
        history.push('/editorships',);
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }
      addToast({
        type: 'error',
        title: 'Erro ao criar Editoria',
        description: 'Não foi possível criar a editoria. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast, editorship?.id]);


  return (
    <>
      <Container>
        <Content>
          <Body>
            <FormContainer onSubmit={handleSubmit}>
              <Input name='name' defaultValue={editorship?.name} type='text' placeholder={isLoading ? 'Carregando...' : 'Nome'} />
              <Input name='url' defaultValue={editorship?.url} type='text' placeholder={isLoading ? 'Carregando...' : 'URL'} />
              <Input name='position' defaultValue={editorship?.position} type='number' placeholder={isLoading ? 'Carregando...' : 'Posição'} />

              <Footer>
                {!isLoading &&
                  <ButtonSave type='submit' disabled={isLoading}>
                    {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar editoria</span></>}
                  </ButtonSave>}
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
