import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.5rem;
  border-radius: 8px;
  flex-wrap: wrap;

  /* @media (min-width: 768px) {
    flex-direction: row;
  } */
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #D1DDF1;
  width: 12rem;
  height: 9rem;
  margin: 0.25rem;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border: 1px solid #006EBF;
    border-radius: 5px;
    background: transparent;
    transition: background-color 0.2s;

    &:hover {
      outline: 1px solid #006EBF;
      background-color: #006EBF;
      color: #fff;
      border-radius: 5px;
    }

    &:active {
      background: #D1DDF1;
    }
  }
`;
