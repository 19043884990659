import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';


import {
  Container,
  Content,
} from './styles';

const UserButton: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Content>
        <div className='user-name'>{user.name.split(' ')[0]}</div>
        <div className='dropdown-content'>
          <ul>
            {/* <li><Link to={`/users/edit/${user.id}`}>Perfil</Link></li>
            <li className='line-separator'></li> */}
            <li><Link to='/users/password/change'>Alterar senha</Link></li>
            <li className='line-separator'></li>
            <li><Link to='/signout'>Sair</Link></li>
          </ul>
        </div>
      </Content>
    </Container>
  );
}

export default UserButton;
