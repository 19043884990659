import { Link } from 'react-router-dom';
import {
  AiOutlineDashboard,
  AiOutlineRead,
  AiOutlinePicture,
  AiOutlineSetting,
  AiOutlineSolution,
  AiOutlineBarChart,
  AiOutlineAreaChart,
  AiFillNotification
} from 'react-icons/ai'

import { useAuth } from '../../hooks/auth';

import {
  Container,
  Content,
  MenuItem,
} from './styles';

const Dashboard = () => {

  const { user } = useAuth();

  function showResource(group: string) {
    if (group === '70417110-bedb-4d17-8891-56b254a1d358' || group === 'b59d94bb-53da-440d-8bf4-025a30d3b990') {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container>
      <Content>
        <MenuItem><Link to='/' title='Dashboard'><AiOutlineDashboard /><span>Dashboard</span></Link></MenuItem>
        <MenuItem><Link to='/news' title='Notícias'><AiOutlineRead /><span>Notícias</span></Link></MenuItem>
        <MenuItem><Link to='/columnists' title='Colunistas'><AiOutlineSolution /><span>Colunistas</span></Link></MenuItem>
        <MenuItem><Link to='/mediacenter' title='Mecia Center'><AiOutlinePicture /><span>Media center</span></Link></MenuItem>
        <MenuItem><Link to='/settings' title='Configurações'><AiOutlineSetting /><span>Configurações</span></Link></MenuItem>
        {
          showResource(user.group_id) &&
          <MenuItem><Link to='/reports' title='Relatórios'><AiOutlineAreaChart /><span>Relatórios</span></Link></MenuItem>
        }
        {
          showResource(user.group_id) &&
          <MenuItem><Link to='/surveys' title='Enquetes'><AiOutlineBarChart /><span>Enquetes</span></Link></MenuItem>
        }
        <MenuItem><Link to='/notifications' title='Notificações'><AiFillNotification /><span>Notificações</span></Link></MenuItem>
      </Content>
    </Container >
  );
}

export default Dashboard;
