import styled, { keyframes } from 'styled-components';

import signInBackground from '../../assets/bg-signin.jpeg'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 80%;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  @media (max-width: 1100px) {
    width: 100%;
  }

  img {
    width: 16rem;
    padding: 0.8px;
    margin-bottom: 2rem;
    filter: drop-shadow(4px 8px 4px rgb(0, 0, 0, 0.4));

    @media (min-width: 1100px) {
      display: none;
    }
  }

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      color: var(--gray-1);
      margin-bottom: 24px;
      filter: drop-shadow(1px 8px 2px rgb(0, 0, 0, 0.4));
    }

    span {
      color: var(--gray-100);
      font-size: 0.85rem;
    }

    .tokenContainer {
      margin-top: 1rem;
      color: var(--gray-100);
      border: 1px solid var(--gray-100);
      border-radius: 6px;
      padding: 0.5rem;
      transition: 0.2s;
      font-size: 0.875rem;

      &:hover{
        cursor: pointer;
        border: 1px solid var(--gray-100);
        border-radius: 5px;
        background: var(--gray-100);
        color: var(--gray-400);
      }

      &:active{
        cursor: pointer;
        border: 1px solid var(--gray-100);
        border-radius: 5px;
        background: var(--gray-200);
        color: var(--gray-400);
      }
    }
  }
`;

export const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  filter: drop-shadow(-80px 80px 8px rgb(0, 0, 0, 0.4));

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
