import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { FiUser, FiLock, FiLoader } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

import Input from '../../components/Input';
import InputPassword from '../../components/InputPassword';
import Button from '../../components/Button';

import imgLogo from '../../assets/rco-logo-w.svg';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Loading
} from './styles';

interface ISignInFormData {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: ISignInFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required('Digite seu e-mail!'),
        password: Yup.string().required('Digite sua senha!'),
      });
      await schema.validate(data, { abortEarly: false });
      setIsLoading(true);
      await signIn({
        email: data.email,
        password: data.password
      });
      setIsLoading(false);
      history.push('/dashboard');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: '' + err,
      });
    }
  }, [history, signIn, addToast]);

  useEffect(()=>{
    fetch('https://rconews-api.rco.com.br/ip').then((res)=>{
      return res.json();
    }).then((data)=>{
      const fullIP: string = data.toString();
      const spliFullIP = fullIP.split('.');
      const network: string = `${spliFullIP[0]}.${spliFullIP[1]}.${spliFullIP[2]}.0`;
      setIpAddress(fullIP);
    })
  },[])

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={imgLogo} alt='Rádio Centro Oeste' width={480} />
            <h1>Fazer logon</h1>
            <Input name="email" placeholder="E-mail" icon={FiUser} autoComplete="none" />
            <InputPassword name="password" placeholder="Senha" icon={FiLock} />
            <Button name="btnCreate" disabled={isLoading}>{isLoading ? <Loading><FiLoader /></Loading> : 'Entrar'}</Button>
            <span>Seu acesso é rastreado e monitorado: {ipAddress}</span><br />
            {ipAddress !== '131.161.191.223' && <span style={{color: 'red', fontWeight: 'bold'}}>Seu IP foi considerado suspeito!</span>}
            <p className="tokenContainer" onClick={()=>{alert('Instale o gerenciador de token da RCO.')}}>Entrar usando Token</p>
          </Form>
        </AnimationContainer>
        <Background>
          <img src={imgLogo} alt='Rádio Centro Oeste' width={480} />
        </Background>
      </Content>

    </Container>
  )
};
