import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { Dashboard } from '../pages/Dashboard';
import { NewsCreate } from '../pages/NewsCreate';
import { NewsEdit } from '../pages/NewsEdit';
import { News } from '../pages/News';
import { Columnists } from '../pages/Columnists';
import { ColumnistDetails } from '../pages/ColumnistDetails';
import { PublicationCreate } from '../pages/PublicationCreate';
import { PublicationEdit } from '../pages/PublicationEdit';
import { SignIn } from '../pages/SignIn';
import { SignOut } from '../pages/SignOut';
import { NotFound } from '../pages/NotFound';
import { MediaCenter } from '../pages/MediaCenter';
import { MediaCreateImage } from '../pages/MediaCreateImage';
import { MediaSelectType } from '../pages/MediaSelectType';
import { MediaEdit } from '../pages/MediaEdit';
import { Settings } from '../pages/Settings';
import { EditorShips } from '../pages/Settings/EditorShips';
import { Users } from '../pages/Users';
import { UserCreate } from '../pages/UserCreate';
import { UserEdit } from '../pages/UserEdit';
import { PasswordChange } from '../pages/PasswordChange';
import { Programs } from '../pages/Programs';
import { ProgramCreate } from '../pages/ProgramCreate';
import { ProgramEdit } from '../pages/ProgramEdit';
import { Logs } from '../pages/Reports/Logs';
import { LogsLgpd } from '../pages/Reports/LogsLgpd';
import { Reports } from '../pages/Reports';
import { PerJournalist } from '../pages/Reports/PerJournalist';
import { LiveVideos } from '../pages/LiveVideos';
import { LiveVideosEdit } from '../pages/LiveVideosEdit';
import { Promotions } from '../pages/Promotions';
import { PromotionEdit } from '../pages/PromotionEdit';
import { Relogio } from '../pages/Relogio';
import { TopFiveNews } from '../pages/TopFiveNews';
import { Notifications } from '../pages/Notifications';
import { NotificationCreate } from '../pages/NotificationCreate';
import { Surveys } from '../pages/Surveys';
import { SurveyCreate } from '../pages/SurveyCreate';
import { SurveyEdit } from '../pages/SurveyEdit';

const Routes: React.FC = () => (
  <Switch >
    <Route path="/" exact component={SignIn} />
    <Route path="/signin" exact component={SignIn} />
    <Route path="/relogio" exact component={Relogio} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/news" exact component={News} isPrivate />
    <Route path="/news/create" component={NewsCreate} isPrivate />
    <Route path="/news/edit/:id" component={NewsEdit} isPrivate />
    <Route path="/columnists" exact component={Columnists} isPrivate />
    <Route path="/columnists/details/:slug" exact component={ColumnistDetails} isPrivate />
    <Route path="/columnists/publications/create" component={PublicationCreate} isPrivate />
    <Route path="/columnists/publications/edit/:id" component={PublicationEdit} isPrivate />
    <Route path="/mediacenter" component={MediaCenter} isPrivate />
    <Route path="/media/create" exact component={MediaSelectType} isPrivate />
    <Route path="/media/create/image" component={MediaCreateImage} exact isPrivate />
    <Route path="/media/edit/:id" component={MediaEdit} isPrivate />
    <Route path="/settings" component={Settings} isPrivate />
    <Route path="/editorships" component={EditorShips} isPrivate />
    <Route path="/users" exact component={Users} isPrivate />
    <Route path="/users/create" component={UserCreate} isPrivate />
    <Route path="/users/edit/:id" component={UserEdit} isPrivate />
    <Route path="/users/password/change" component={PasswordChange} isPrivate />
    <Route path="/programs" exact component={Programs} isPrivate />
    <Route path="/programs/create" component={ProgramCreate} isPrivate />
    <Route path="/programs/edit/:id" component={ProgramEdit} isPrivate />
    <Route path="/signout" component={SignOut} isPrivate />
    <Route path="/reports" exact component={Reports} isPrivate />
    <Route path="/reports/news/per-journalist" component={PerJournalist} isPrivate />
    <Route path="/livevideos" exact component={LiveVideos} isPrivate />
    <Route path="/livevideos/edit/:id" component={LiveVideosEdit} isPrivate />
    <Route path="/promotions" exact component={Promotions} isPrivate />
    <Route path="/promotions/edit/:id" exact component={PromotionEdit} isPrivate />
    <Route path="/reports/logs" component={Logs} isPrivate />
    <Route path="/reports/logslgpd" component={LogsLgpd} isPrivate />
    <Route path="/topfive" exact component={TopFiveNews} isPrivate />
    <Route path="/notifications" exact component={Notifications} isPrivate />
    <Route path="/notifications/create" component={NotificationCreate} isPrivate />
    <Route path="/surveys" exact component={Surveys} isPrivate />
    <Route path="/surveys/create" component={SurveyCreate} isPrivate />
    <Route path="/surveys/edit/:id" exact component={SurveyEdit} isPrivate />
    <Route path="*" component={NotFound} />
    <Route path="*" component={Dashboard} isPrivate />
  </Switch >
);

export default Routes;
