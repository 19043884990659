import { ChangeEvent, useCallback, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { FiUploadCloud, FiXCircle, FiSave, FiLoader } from 'react-icons/fi';

import api from '../../services/api';

import { Container, Content, Body, HeaderBody,BodyContent, Footer, ButtonSave, ButtonCancel, Loading, UploadButton } from './styles';

const ModalMediaCreateVideo = ({ id = 'thismodal', onClose }: any) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [fileSrc, setFileSrc] = useState('')
  const [description ,setDescription] = useState('')
  const [credits ,setCredits] = useState('')
  const [file, setFile] = useState<any>()

  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  }

  function selectImage(e: ChangeEvent<HTMLInputElement>) {
    if(e.target.files){

      setFileSrc(e.target.files[0].name)
      setFile(e.target.files[0])

      let src = URL.createObjectURL(e.target.files[0])
      let userFile = new Audio()
      userFile.src = src
      setFile(e.target.files[0])
      setFileSrc(src)
      setIsLoading(false)
    }
  }

  const handleUploadMedia = useCallback(async ()=>{
    const dataForm = new FormData();

    if (description === '') {
      alert('Escreva uma descrição.');
      return;
    }

    if (credits === '') {
      alert('Informe os Direitos autorais.');
      return;
    }
    console.log(file)
    try{
      setIsLoading(true)
      dataForm.append('description', description);
      dataForm.append('credits', credits);
      dataForm.append('file', file);
      dataForm.append('type', 'video');

      await api.post('medias/upload', dataForm);
      history.push('/mediacenter')
      setIsLoading(false)
    }catch(err){
      alert(err)
      setIsLoading(false)
    }
  },[description, credits, file, history])

  return (
    <Container id={id} onClick={handleOutsideClick}>
      <Content>
        <Body>
          <HeaderBody>
            <span className='titleHeaderBody'>Selecionar vídeo</span>
            <button className='buttonClose' onClick={onClose}><FiXCircle /></button>
          </HeaderBody>
          <BodyContent id='bodycontent'>
            {
              !fileSrc ?
              <>
                <UploadButton htmlFor="file">
                  <FiUploadCloud size={92} />
                  <h3>Clique para enviar</h3>
                </UploadButton>
              </> :
              <>
                {isLoading ? 'Processando...' :
                <>
                {

                }
                  <video src={fileSrc} controls id='audioPlayer' style={{width: '80%'}} />
                </>}
              </>
            }
            <input type="file" src={fileSrc} name="file" id="file" accept=".mp4" onChange={(e)=>{selectImage(e)}} />
          </BodyContent>
          <Footer>
            <div className='leftContent'>
              <input name='description' type="text" placeholder='Descrição' style={{width: '480px'}} onChange={(e)=>{setDescription(e.target.value)}} />
              <input name='credits' type="text" placeholder='Direitos autorais' style={{width: '300px'}} onChange={(e)=>{setCredits(e.target.value)}} />
            </div>
            <div className='rightContent'>
              <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
              <ButtonSave disabled={fileSrc.length <= 0 || isLoading} onClick={handleUploadMedia}>
                {
                isLoading ? <Loading><FiLoader /></Loading> :
                <>
                  <span>Salvar</span>
                  <FiSave />
                </>
                }
              </ButtonSave>
            </div>
          </Footer>
        </Body>
      </Content>
    </Container>
  );
}

export {ModalMediaCreateVideo};
