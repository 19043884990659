import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiCheck, FiArrowLeft, FiLoader } from 'react-icons/fi'

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../components/Header';
import Menu from '../../components/Menu';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  FormContainer,
  Input,
  Select,
  Footer,
  ButtonSave,
  Loading
} from './styles';

interface IGroupProps {
  id: string;
  name: string;
}

export const UserCreate = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<IGroupProps[]>([]);

  useEffect(() => {
    setIsLoading(true);
    api.get('/groups').then(response => {
      setGroups(response.data);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome é obrigatório!'),
        email: Yup.string().required('E-mail é obrigatório!'),
        group: Yup.string().required('Selecione um grupo!'),
        password: Yup.string().required('Senha é obrigatório!'),
      });

      let formData = {
        name: event.target['name'].value,
        email: event.target['email'].value,
        group: event.target['group'].value,
        password: event.target['password'].value,
      };

      setIsLoading(true);

      const isValid = await schema.isValid(formData, { abortEarly: false });

      if (isValid) {
        await api.post('/users', formData, { headers: api.defaults.headers });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'O usuário foi criado com sucesso.'
        });
        setIsLoading(false);
        history.push('/users',);
      } else {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Verifique as informações do formulário.'
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return errors;
      }

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Não foi possível criar o usuário. Verifique se as informações.' + err,
      })
    } finally {
      setIsLoading(false);
    }
  }, [history, addToast]);

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Criar Usuário' />
          <Body>
            <HeaderBody>
              <Link to="/users"><FiArrowLeft /> Voltar</Link>
            </HeaderBody>
            <FormContainer onSubmit={handleSubmit}>
              <Input name='name' type='text' placeholder='Nome' />
              <Input name='email' type='email' placeholder='E-mail' />
              <Select name='group'>
                <option value="">Selecione um grupo</option>
                {
                  groups && groups.map((group: IGroupProps) => {
                    return <option key={group.id} value={group.id}>{group.name}</option>
                  })
                }
              </Select>
              <Input name='password' type='password' placeholder='Senha' />

              <Footer>
                <ButtonSave type='submit' disabled={isLoading}>
                  {isLoading ? <Loading><FiLoader /></Loading> : <><FiCheck /><span>Salvar</span></>}
                </ButtonSave>
              </Footer>
            </FormContainer>
          </Body>
        </Content>
      </Container>
    </>
  )
}
