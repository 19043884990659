import React from 'react';

import imgLogo from '../../assets/rco-logo.svg';

import {
  Container,
  Content
} from './styles';

export const NotFound: React.FC = () => {
  return (
    <Container>
      <Content>
        <img src={imgLogo} alt='Rádio Centro Oeste' width={300} />
        <h1>Erro 404</h1>
        <h2>Ops! Conteúdo não encontrado!</h2>
      </Content>
    </Container>
  );
}
