import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiEdit, FiLink } from 'react-icons/fi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import imgAvatarDefault from '../../assets/default-avatar.png';
import imgAudioDefault from '../../assets/audio_icon.png';
import imgVideoDefault from '../../assets/video_icon.png';
import api from '../../services/api';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/toast';
import { Modal } from '../../components/Modal';
import { UploadMedia } from '../../components/UploadMedia';

import {
  Container,
  Content,
  Body,
  HeaderBody,
  SearchContent,
  ButtonCreate,
  ListContent
} from './styles';

interface IUserProps {
  id: string;
  name: string;
  avatar: string;
  group: IGroupProps;
}

interface IGroupProps {
  id: string;
  name: string;
}

interface IFileProps {
  id: string;
  description: string;
  credits: string;
  url: string;
  filename: string;
  user: IUserProps;
  created_at: Date;
}

export const MediaCenter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<IFileProps[]>([]);
  const { addToast } = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const [page, setPage] = useState(1);

  useEffect(() => {
    try {
      api.get(`/medias?page=${page}`, { timeout: 5000 }).then(response => {
        setFiles(response.data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: '' + err,
        });
        if (err.message === 'Request failed with status code 401') {
          history.push('/signout');
        }
      }).finally(() => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro nos dados',
        description: 'Erro inesperado. Entre em contato com o desenvolvedor.',
      });
    }
  }, [addToast, history, page]);

  const handleSearch = useCallback(async (e) => {
    if (e.key === 'Enter') {
      const searchContent = e.target.value;

      setIsLoading(true);

      try {
        setPage(1);
        const response = await api.get(`/medias?description=${searchContent}&page=${1}`, { timeout: 5000 });
        setFiles(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  // const handleOpenModal = useCallback(() => {
  //   setIsModalVisible(true);
  // }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
    document.location.reload();
  }, []);

  const handleUrlCopied = useCallback((urlDescrition: string) => {
    addToast({
      type: 'info',
      title: 'URL Copiada!',
      description: urlDescrition,
    });
  }, [addToast]);

  const defineThumbnailMediaItem = useCallback((mediaObject: string) => {
    const mediaType = mediaObject.split('.')[3];
    // console.log('Arquivo: ' + mediaType)
    if (mediaType === 'mp3' || mediaType === 'mpeg') {
      return imgAudioDefault;
    } else if (mediaType === 'mp4') {
      return imgVideoDefault;
    }
    else {
      return mediaObject;
    }

  }, []);

  const deleteMedia = useCallback(async (id: string) => {

    const answer = window.confirm('Deseja realmente Excluir a Mídia?');

    if (answer) {
      setIsLoading(true);

      try {
        await api.delete(`/medias/${id}`, { timeout: 5000 });
        setIsLoading(false);
        document.location.reload();
      } catch (err) {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro nos dados',
          description: '' + err,
        });
      }
    }
  }, [addToast]);

  const handleBackPage = useCallback(() => {
    if (page >= 2) {
      setIsLoading(true);
      setPage(page - 1);
    }
  }, [page]);

  const handleForwardPage = useCallback(() => {
    if (files.length === 10) {
      setIsLoading(true);
      setPage(page + 1);
    }
  }, [page, files]);

  const getFileInfos = (urlfile: string) => {
    const newImage = new Image();
    newImage.onload = () => {

    }
    newImage.src = urlfile;

    const fileInfos = {
      with: newImage.width,
      height: newImage.height,
    }

    return fileInfos;
  }

  const handleAudioEmbedGenerate = useCallback((_url: string, _description: string, _credits: string)=>{
    const typeFile = _url.split('.')[(_url.split('.').length) - 1]
    if(typeFile === 'mp3'){
      return `<iframe id="icpap" src="https://audioplayer.rco.com.br/?audioSrc=${_url}&description=${_description}&credits=${_credits}" width="100%" height="128px"></iframe>`
    }else if (typeFile === 'mp4') {
      return `<iframe id="toa" src="https://videoplayer.rco.com.br/?videoSrc=${_url}&description=${_description}&credits=${_credits}" width="100%" height="auto"></iframe>`
    }else{
      return _url
    }
  },[])

  return (
    <>
      <Container>
        <Menu />
        <Content>
          <Header pageTitle='Mídias' />
          <Body>
            <HeaderBody>
              <SearchContent>
                <input type="search" placeholder='Pesquisar pela descrição' onKeyPress={(e) => { handleSearch(e) }} />
                {/* <button onClick={(handleSearch)}><AiOutlineSearch /></button> */}
              </SearchContent>
              <ButtonCreate onClick={() => { history.push('/media/create') }}>
                <FiEdit />
                <span>Criar mídia</span>
              </ButtonCreate>
            </HeaderBody>
            <ListContent>
              <div className='pageControl'>
                <button onClick={handleBackPage} style={{ cursor: 'pointer' }}><FaAngleLeft /></button>
                <span>{page}</span>
                <button onClick={handleForwardPage} style={{ cursor: 'pointer' }}><FaAngleRight /></button>
              </div>
              {isLoading ?
                <h2>Carregando dados...</h2>
                :
                <table>
                  <thead>
                    {files.length > 0 ?
                      <tr>

                      </tr>
                      : <h3>Não há dados</h3>
                    }
                  </thead>
                  <tbody>
                    {
                      files && files.map((mediaItem: IFileProps) =>
                        <tr key={mediaItem.id} onDoubleClick={() => { deleteMedia(mediaItem.id) }}>
                          <td>
                            <Link to={`/media/edit/${mediaItem.id}`} rel="noreferrer"><img src={mediaItem.url && defineThumbnailMediaItem(mediaItem.url)} alt="Arquivo de mídia" /></Link>
                            <div className='containerMediaInfo'>
                              <div className='descriptionContent'>
                                <h4><Link to={`/media/edit/${mediaItem.id}`}>{mediaItem.description}</Link></h4>
                                <span>Créditos/Direitos Autorais: {mediaItem.credits}</span>
                              </div>
                              <div className='copyURL'>
                                <CopyToClipboard text={handleAudioEmbedGenerate(mediaItem.url, mediaItem.description, mediaItem.credits)}
                                  onCopy={() => { handleUrlCopied(mediaItem.description) }}>
                                  {/* onCopy={() => setUrlCopied(true)}> */}
                                  <span><FiLink />Copiar LINK</span>
                                </CopyToClipboard>
                              </div>
                              <div className='fileInfos'>
                                <span><strong>Resolução: </strong>{getFileInfos(mediaItem.url).with}x{getFileInfos(mediaItem.url).height}</span>
                              </div>
                              <div className='userContainer'>
                                <img src={mediaItem.user.avatar ? mediaItem.user.avatar : imgAvatarDefault} alt={mediaItem.user.name} />
                                <span>{mediaItem.user.name}</span>
                              </div>
                              <div className='createdContainer'>
                                <span>{new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(mediaItem.created_at))}</span>
                              </div>
                              {/* <div className='imageSize'>
                                <span><strong>Tamanho</strong></span>
                                <span>0 KB</span>
                              </div> */}
                              {/* <div className='imageResolution'>
                                <span><strong>Resolução</strong></span>
                                <span>W: {imgResolution(mediaItem.url).w}px</span>
                                <span>H: {imgResolution(mediaItem.url).h}px</span>
                              </div> */}
                            </div>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              }
            </ListContent>
          </Body>
        </Content>
        {isModalVisible ? <Modal title='Cadastrar mídia' onClose={() => handleCloseModal()}><UploadMedia /></Modal> : null}
      </Container>
    </>
  )
}
