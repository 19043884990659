import { useEffect, useState } from 'react';

import {
  Container
} from './styles';

export const Relogio = () => {
  const [relogio, setRelogio] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRelogio(new Date())
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <span>{relogio.toLocaleTimeString('pt-BR', {timeZone: 'America/Sao_Paulo'}).substring(0,5)}</span>
    </Container>
  )
}
