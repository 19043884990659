import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: transparent;

  span {
    @media (min-width: 100px) {
      font-size: 32px;
    }

    @media (min-width: 200px) {
      font-size: 64px;
    }

    @media (min-width: 300px) {
      font-size: 100px;
    }

    @media (min-width: 400px) {
      font-size: 140px;
    }

    @media (min-width: 500px) {
      font-size: 180px;
    }

    @media (min-width: 600px) {
      font-size: 220px;
    }

    @media (min-width: 700px) {
      font-size: 245px;
    }

    @media (min-width: 800px) {
      font-size: 280px;
    }
  }
`;
