import { useEffect } from "react";
import { useAuth } from "../../hooks/auth";

export const SignOut: React.FC = () => {

  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <h1>Sign Out</h1>
};
