import React, { useEffect } from 'react';

import UserButton from '../UserButton';

import {
  Container,
  Content,
} from './styles';

interface HeaderProps {
  pageTitle: string;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, ...rest }) => {

  const NotificationClk = document.querySelector('.teste');

  useEffect(() => {
    if (!window.Notification) {
      console.log('Navegador não tem suporte para Notificação');
      // return;
    } else {
      if ((Notification.permission !== 'granted')) {
        Notification.requestPermission();
      }
    }
  }, []);

  const requestPermission = async () => {
    if (!window.Notification) {
      console.log('Navegador não tem suporte para Notificação');
      return;
    }

    if (Notification.permission !== 'granted')
      await Notification.requestPermission();
    else {
      let notification = new Notification('Título da Notificação', {
        icon: '/favicon.png',
        body: 'Corpo da Notícia',
      });
      notification.onclick = function () {
        alert('Clicou na notificação');
      };
    }
  }

  NotificationClk?.addEventListener('click', requestPermission)

  return (
    <Container>
      <Content>
        <span className='teste'>{pageTitle}</span>
        <UserButton />
      </Content>
    </Container>
  );
}

export default Header;
