import React, { ButtonHTMLAttributes } from "react";

import { ButtonContainer } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
}

const Button: React.FC<ButtonProps> = ({ children, type, ...rest }) => (
  <ButtonContainer {...rest} type={type}>{children}</ButtonContainer>
);

export default Button;
