import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  .tox-statusbar__branding {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F7FC;
  overflow-y: scroll;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  /* background: #fff; */
  margin: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;

  .tox-tinymce {
    border: 1px solid #D1DDF1;
    border-radius: 5px;
    min-height: 540px;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  transform: color 0.2s;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--gray-200);
    }
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ButtonFeature = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #45CB31;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.75rem 1.5rem;
  transition: background-color 0.2s;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #3AAE28;
  }

  @media (max-width: 648px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  display: flex;
  background-color: #fff;
  border: 1px solid #D1DDF1;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  outline: none;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 2rem;
  gap: 1rem;

  span:hover {
    cursor: pointer;
    color: #006EBF;
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: #fff;
  padding: 0.875rem;
  border-radius: 5px;
  transition: background-color 0.2s;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }

  @media (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
  }
`;


export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 2px solid #45CB31;

  .headerQuestions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.25rem;
    padding: 0.5rem 0;

    .createQuestionContainer {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 1rem;

      .createQuestionInput {
        display: none;
        flex-direction: row;
        gap: 1rem;

        input {
          display: flex;
          max-width: 30rem;
          width: 30rem;
          padding: 0.5rem 0.5rem;
          outline: none;
          border: 1px solid #45CB31;
          border-radius: 5px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;;
          border: 1px solid #45CB31;
          border-radius: 5px;
          background: #45CB31;
          color: #fff;

          &:hover {
            background: #add631;
          }
        }
      }
    }
  }
`;

export const QuestionsContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #45CB31;
  padding: 0.5rem;
  border-radius: 8px;
  gap: 1rem;

  input {
    display: flex;
    max-width: 30rem;
    padding: 0.5rem 0.5rem;
    outline: none;
    border: 1px solid #45CB31;
    border-radius: 5px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;;
    border: 1px solid #45CB31;
    border-radius: 5px;
    background: #45CB31;
    color: #fff;

    &:hover {
      background: #add631;
    }
  }
`;

export const QuestionItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  input {
    display: flex;
    max-width: 30rem;
    width: 100%;
    padding: 0.5rem 0.5rem;
    outline: none;
    border-radius: 5px;

    &:read-only {
      background: #dadada;
    }

    &:focus {
      outline: 1px solid #45CB31;
    }
  }

  button {
    background: #dd5500;
    border: none;

    &:hover {
      background: #ff1100;
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
