import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(0,0,0,0.3);
  z-index: 1;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 95%;
  background: #242432;
  overflow-y: hidden;
  border-radius: 12px;
  box-shadow: 6px 6px 12px rgba(0,0,0,0.6);

  animation-name: contentAnimation;
  animation-duration: 0.45s;
  animation-timing-function: ease;

  @keyframes contentAnimation {
    0%   {margin-top: -400px; opacity: 0}
    75% {opacity: 1}
    100% {margin-top: 0; opacity: 1}
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: color 0.2s;
  height: 4rem;
  border-bottom: 1px solid var(--gray-400);

  .titleHeaderBody {
    padding: 0 1rem;
    color: var(--gray-200)
  }

  .buttonClose {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;

    svg {
      width: 2rem;
      height: 2rem;
      color: var(--gray-400);
      transition: all 0.2s;

      &:hover {
        color: var(--gray-200);
      }
    }
  }
`;

export const BodyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--gray-200);

  input[type="file"] {
    display: none;
  }

  img {
    display: flex;
    margin: auto;
    object-fit: contain;
    width: 98%;
    height: 98%;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid var(--gray-400);
  color: var(--gray-200);
  gap: 2rem;

  .leftContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    input {
      display: flex;
      padding: 0.5rem 1rem;
      background: none;
      border: 1px solid #006EBF;
      border-radius: 5px;
      color: var(--gray-100);

      &:focus {
        border-color: #9E94FB;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const UploadButton = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  margin: auto;
  gap: 1rem;
  color: #524bbc;

  svg {
    transition: all 0.2s;

    &:hover {
      color: #9E94FB;
    }
  }
`

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #006EBF;
  color: var(--gray-100);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.2s;
  gap: 0.5rem;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: #014F87;
  }

  &:active {
    background-color: #006EBF;
  }

  &:disabled {
    background: var(--gray-750);
    color: var(--gray-400);
  }
`;

export const ButtonCancel = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--gray-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.2s;
  gap: 0.5rem;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
  }

  &:hover {
    background-color: var(--gray-750);
  }

  &:active {
    background-color: var(--gray-400);
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loadAnim 2s infinite;
  font-size: 4rem;

  @keyframes loadAnim {
    100% {transform: rotate(360deg);}
  }
`;
